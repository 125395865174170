import './customersTable.css';
import { Link } from 'react-router-dom';

const CustomersTable = ({ customers }) => {
  return (
    <div className="products-table">
      <div className="bookslist-container">
        <table className="productstable-table">
          <tr>
            <th>الإسم</th>
            <th>الهاتف</th>
            <th>الإيمايل</th>
            <th>عدد الطلبيات</th>
            <th>تاريخ التسجيل</th>
            <th>تعديل</th>
          </tr>
          {customers.map((costumer) => {
            return (
              <tr key={costumer.id}>
                <td>
                  <Link
                    to={`/cp/customer/${costumer.id}`}
                    className="link table-link"
                  >
                    {costumer.name}
                  </Link>
                </td>
                <td>{costumer.phone}</td>
                <td>{costumer.email}</td>
                <td>{costumer.orders}</td>
                <td>{costumer.date}</td>
                <td>
                  <button className="table-btn-edit">
                    <Link to="/" className="link">
                      تعديل
                    </Link>
                  </button>
                  <button className="table-btn-delete" onClick={() => {}}>
                    حذف
                  </button>
                </td>
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  );
};

export default CustomersTable;
