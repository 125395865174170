import './dynamic.css';
import BookCard from '../molecules/BookCard';

const DynamicShow = ({ products }) => {
  return (
    <div className="dynamic-container">
      {products.map((product, index) => {
        return <BookCard product={product} key={index} />;
      })}
    </div>
  );
};

export default DynamicShow;
