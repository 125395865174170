import React from "react";

const LabeledInput = ({ name, label, type, placeholder, value, ...props }) => {
  return (
    <div>
      <label
        className="block text-md font-medium text-slate-700"
        htmlFor={name}
      >
        {label}
      </label>
      <input
        name={name}
        className="drop-shadow-sm border-[1px] focus:outline-none block py-2 px-4 my-2	rounded-lg w-80 border-slate-200 placeholder-slate-400 contrast-more:border-slate-400 contrast-more:placeholder-slate-500"
        type={type}
        value={value}
        placeholder={placeholder}
        {...props}
      />
    </div>
  );
};

export default LabeledInput;
