import { Dialog } from "@headlessui/react";

const Modal = ({ isOpen, setIsOpen, title, children }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="relative z-50"
    >
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto rounded-2xl bg-white drop-shadow-md p-6">
          <div className="overflow-auto max-h-[500px]">
            <Dialog.Title className="text-center text-xl font-bold mb-4">
              {title}
            </Dialog.Title>
            {children}
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default Modal;
