import "./checkout.css";
import NavContainer from "../../NavContainer";
import { useState, useContext } from "react";
import CartContext from "../../CartContext";
import CheckoutForm from "../../components/organisms/CheckoutForm";
import CheckoutConfirmation from "../../components/organisms/CheckoutConfirmation";
import { useDebounce } from "use-debounce";
import WILAYAS from "../../wilayas";
import { axiosInstance } from "../../config";

const Checkout = () => {
  const { totalPrice } = useContext(CartContext);
  const [step, setStep] = useState(1);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [confirmPhone, setConfirmPhone] = useState("");
  const [email, setEmail] = useState("");
  const [addressDetails, setAddressDetails] = useState("");
  const [wilaya, setWilaya] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [codePromoValue] = useDebounce(promoCode, 1000);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [codePromoError, setCodePromoError] = useState("");

  const nextStep = () => {
    setStep((prev) => prev + 1);
  };

  const prevStep = () => {
    setStep((prev) => prev - 1);
  };

  const data = {
    firstName,
    lastName,
    phone,
    confirmPhone,
    email,
    addressDetails,
    wilaya,
    deliveryCharge,
    totalPrice,
    promoCode,
    codePromoValue,
    codePromoError,
    discountPercentage,
  };

  const calculateTotalPrice = () => {
    if (discountPercentage !== 0) {
      return (
        totalPrice - (totalPrice * discountPercentage) / 100 + deliveryCharge
      );
    }
    return totalPrice + deliveryCharge;
  };

  const deliveryPrice = (wilaya) => {
    for (let i = 0; i < WILAYAS.length; i++) {
      if (WILAYAS[i].name === wilaya) {
        return WILAYAS[i].price;
      }
    }
  };

  const calculateItemsPrice = () => {
    if (discountPercentage !== 0) {
      return totalPrice - (totalPrice * discountPercentage) / 100;
    }
    return totalPrice;
  };

  const handleWilayaChange = (e) => {
    setWilaya(e.target.value);
    // if (e.target.value === "ALGER") {
    //   setDeliveryCharge(400);
    // } else {
    console.log(e.target.value);
    console.log(deliveryPrice(e.target.value));
    const deliveryPriced = deliveryPrice(e.target.value);
    setDeliveryCharge(deliveryPriced);

    // if (deliveryPrice(e.target.value) >= 900) {
    // } else {
    //   setDeliveryCharge(700);
    // }
    // }
  };

  const checkCodePromoValidity = () => {
    if (promoCode !== "") {
      axiosInstance
        .get(`/api/promoCodes/check/${codePromoValue}`)
        .then((res) => {
          if (res.data.data.isValid === true) {
            setDiscountPercentage(res.data.data.discountPercentage);
            setCodePromoError("");
          } else {
            setCodePromoError("الرمز غير صالح");
            setDiscountPercentage(0);
          }
        })
        .catch((err) => {
          setCodePromoError("الرمز غير صالح");
          setDiscountPercentage(0);
          console.log(err);
        });
    } else {
      setCodePromoError("");
      setDiscountPercentage(0);
    }
  };

  const methods = {
    setFirstName,
    setLastName,
    setPhone,
    setConfirmPhone,
    setEmail,
    setAddressDetails,
    setWilaya,
    setDeliveryCharge,
    setPromoCode,
    calculateTotalPrice,
    handleWilayaChange,
    checkCodePromoValidity,
    calculateItemsPrice,
  };

  return (
    <NavContainer>
      <div className="min-h-[50vh] my-6 mx-2 md:mx-16">
        {step === 1 && (
          <CheckoutForm nextStep={nextStep} data={data} methods={methods} />
        )}
        {step === 2 && (
          <CheckoutConfirmation
            prevStep={prevStep}
            data={data}
            calculateTotalPrice={calculateTotalPrice}
            calculateItemsPrice={calculateItemsPrice}
          />
        )}
      </div>
    </NavContainer>
  );
};

export default Checkout;
