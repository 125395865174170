import CircularProgress from '@mui/material/CircularProgress';
import './loading.css'

const Loading = () => {
  return (
    <div className='loading-container'>
        <CircularProgress size={60} color="secondary" />
    </div>
  )
}

export default Loading