import "./signup.css";
import NavContainer from "../../NavContainer";
import { useContext, useState } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import WILAYAS from "../../wilayas";
import { axiosInstance } from "../../config";
import UserContext from "../../UserContext";

const Signup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [addressDetails, setAddressDetails] = useState("");
  const [wilaya, setWilaya] = useState("");
  const { token, setToken } = useContext(UserContext);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await axiosInstance
      .post("/api/auth/signup", {
        firstName,
        lastName,
        email,
        phone: mobileNumber,
        password,
        addressDetails,
        wilaya,
      })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        setToken(res.data.token);
        setEmail("");
        setPassword("");
        navigate(from, { replace: true });
      });
  };

  return !token ? (
    <NavContainer>
      <div className="signup-container">
        <h3>تسجيل مستخدم جديد</h3>
        <form action="/" className="signup-form" onSubmit={handleSubmit}>
          <div className="signup-row">
            <div className="signup-row-side">
              <label htmlFor="">الإسم الأول</label>
              <input
                type="text"
                placeholder="الإسم الأول"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="signup-row-side">
              <label htmlFor="">إسم العائلة</label>
              <input
                type="text"
                placeholder="إسم العائلة"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>
          <div className="signup-row">
            <div className="signup-row-side">
              <label htmlFor="">رقم الهاتف</label>
              <input
                type="text"
                placeholder="رقم الهاتف"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
              />
            </div>
            <div className="signup-row-side">
              <label htmlFor="">الإيمايل</label>
              <input
                type="email"
                placeholder="الإيمايل"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="signup-row">
            <div className="signup-row-side">
              <label htmlFor="">كلمة السر</label>
              <input
                type="password"
                placeholder="كلمة السر"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="signup-row-side">
              <label htmlFor="">تأكيد كلمة السر</label>
              <input
                type="password"
                placeholder="تأكيد كلمة السر"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="signup-row">
            <div className="signup-row-side">
              <label htmlFor="">الولاية</label>
              <select
                name="wilaya"
                id=""
                onChange={(e) => setWilaya(e.target.value)}
              >
                {WILAYAS.map((w, i) => {
                  return (
                    <option key={i} value={w.name}>
                      {w.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="signup-address">
            <label htmlFor="">العنوان</label>
            <textarea
              placeholder="العنوان"
              name="address"
              id=""
              cols="30"
              rows="10"
              value={addressDetails}
              onChange={(e) => setAddressDetails(e.target.value)}
            />
          </div>
          <div className="signup-btn">
            <button type="submit">تسجيل المستخدم</button>
          </div>
        </form>
      </div>
    </NavContainer>
  ) : (
    <Navigate to="/" />
  );
};

export default Signup;
