import "./footer.css";
import { Link } from "react-router-dom";
import { AiFillFacebook, AiOutlineMail } from "react-icons/ai";
import { FaInstagramSquare, FaWhatsappSquare } from "react-icons/fa";
import { GoLocation } from "react-icons/go";
import { BiPhoneCall } from "react-icons/bi";

const Footer = () => {
  return (
    <div className="bg-[#192d42] flex flex-col sm:flex-row justify-evenly mt-10 py-8">
      <div className="flex items-center justify-center">
        <img className="w-36" src="./../static/logo_white.png" alt="" />
      </div>
      <div className="flex flex-col items-center justify-center text-center">
        <ul className="footer-ul">
          <li>
            <Link className="footer-link" to="/contact-us">
              تواصل معنا
            </Link>
          </li>
          <li>
            <Link className="footer-link" to="/about-us">
              من نحن؟
            </Link>
          </li>
          <li>
            <Link className="footer-link" to="/delivery-policy">
              سياسة التوصيل
            </Link>
          </li>
          <li>
            <Link className="footer-link" to="/returning-items">
              سياسة الإرجاع
            </Link>
          </li>
          <li>
            <Link className="footer-link" to="/offers">
              تخفضيات
            </Link>
          </li>
        </ul>
      </div>
      <div className="flex flex-col items-center justify-center text-center">
        <div className="footer-contact">
          <div className="footer-contact-item">
            <GoLocation color="#fff" size={30} />
            <p>الرويبة - الجزائر</p>
          </div>
          <div className="footer-contact-item">
            <BiPhoneCall color="#fff" size={30} />
            <p>213.561.67.87.70+</p>
          </div>
          <div className="footer-contact-item">
            <AiOutlineMail color="#fff" size={30} />
            <p>contact@naqra.net</p>
          </div>
        </div>
        <div className="flex flex-row mt-4 gap-2">
          <a href="https://www.facebook.com/naqraa.store" target="_blank">
            <AiFillFacebook color="#fff" size={40} />
          </a>
          <a href="https://www.instagram.com/naqraa.store" target="_blank">
            <FaInstagramSquare color="#fb3958" size={40} />
          </a>
          <a
            href="https://api.whatsapp.com/send/?phone=213561678770&text&app_absent=0"
            target="_blank"
          >
            <FaWhatsappSquare color="#25D366" size={40} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
