import "./specialOffers.css";
import NavContainer from "../../NavContainer";
import DynamicShow from "../../components/organisms/DynamicShow";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../config";
import Loading from "../Loading";
import Pagination from "../../components/organisms/Pagination";

const SpecialOffers = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const { data } = await axiosInstance.get("../api/products/", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const finalData = data.data.filter((product) => {
          return product.sale === true;
        });
        setProducts(finalData);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchProducts();
  }, []);

  return (
    <NavContainer>
      {loading ? (
        <Loading />
      ) : (
        <div className="special-offers-container">
          <Pagination data={products} />
        </div>
      )}
    </NavContainer>
  );
};

export default SpecialOffers;
