import React, { useEffect } from "react";
import "./search.css";
import NavContainer from "../../NavContainer";
import { useLocation, Navigate } from "react-router-dom";
import DynamicShow from "../../components/organisms/DynamicShow";

const Search = () => {
  const location = useLocation();

  return (
    <NavContainer>
      <div className="search-container">
        {location.state ? (
          location.state.products.length !== 0 ? (
            <DynamicShow products={location.state.products} />
          ) : (
            <h1>لم نجد ما تبحث عنه</h1>
          )
        ) : (
          <Navigate to="/" />
        )}
      </div>
    </NavContainer>
  );
};

export default Search;
