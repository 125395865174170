import "./select.css";

const Select = ({ name, title, def, values, ...props }) => {
  return (
    <>
      <label className="block text-md font-medium text-slate-700">
        {title}
      </label>
      <select
        className="drop-shadow-sm border-[1px] focus:outline-none block py-2 px-2 my-2	rounded-lg w-80 border-slate-200 placeholder-slate-400 contrast-more:border-slate-400 contrast-more:placeholder-slate-500"
        {...props}
      >
        {values.map((e, i) => (
          <option defaultValue={def} key={i} value={e.value}>
            {e.option}
          </option>
        ))}
      </select>
    </>
  );
};

export default Select;
