import "./cart.css";
import NavContainer from "../../NavContainer";
import { useContext } from "react";
import CartContext from "../../CartContext";
import CartItem from "../../components/organisms/CartItem";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const Cart = () => {
  const { items, emptyCart, totalPrice, calculateTotalPrice } =
    useContext(CartContext);

  useEffect(() => {
    calculateTotalPrice();
  });

  return (
    <NavContainer>
      <div className="my-10 mx-2 md:mx-12 min-h-[50vh]">
        <div className="cart-top">
          <div className="cart-header">
            <h1>سلة المشتريات</h1>
            <div className="cart-total-price">
              <span>السعر الإجمالي: {totalPrice}.00DA</span>
            </div>
          </div>
          <div className="cart-checkout">
            {items.length !== 0 && (
              <Link to="/checkout" className="cart-checkout-btn link mt-2">
                المتابعة إلى الطلب
              </Link>
            )}
          </div>
        </div>
        {items.length !== 0 ? (
          items.map((item, index) => {
            return (
              <CartItem
                key={index}
                id={item.id}
                img={item.img}
                name={item.name}
                price={item.price}
              />
            );
          })
        ) : (
          <h1>السلة فارغة</h1>
        )}
        <div className="cart-bottom">
          <input
            type="button"
            value="إفراغ السلة"
            className="cart-bottom-btn"
            onClick={() => emptyCart()}
          />
          <Link to="/" className="cart-checkout-btn link">
            العودة للتسوق
          </Link>
        </div>
      </div>
    </NavContainer>
  );
};

export default Cart;
