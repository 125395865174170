import "./checkoutForm.css";
import WILAYAS from "../../wilayas";
import { useEffect, useState } from "react";

const CheckoutForm = ({ nextStep, data, methods }) => {
  // const { totalPrice } = useContext(CartContext);

  const validate = () => {
    if (
      !data.firstName ||
      !data.lastName ||
      !data.phone ||
      !data.confirmPhone ||
      !data.wilaya ||
      !data.addressDetails
    )
      return true;
    if (data.confirmPhone !== data.phone) return true;
    return false;
  };

  useEffect(() => {
    methods.checkCodePromoValidity();
    methods.calculateTotalPrice();
  }, [data.codePromoValue]);

  const handleNextStep = (e) => {
    e.preventDefault();
    nextStep();
  };
  return (
    <>
      <div className="flex flex-wrap">
        <form action="/" className="flex flex-col items-center p-4 flex-1">
          <h3>للطلب... الرجاء ملئ الإستمارة</h3>
          <div className="checkout-row">
            <div className="checkout-row-side">
              <label htmlFor="">الإسم الأول</label>
              <input
                type="text"
                placeholder="الإسم الأول"
                value={data.firstName}
                onChange={(e) => methods.setFirstName(e.target.value)}
              />
            </div>
            <div className="checkout-row-side">
              <label htmlFor="">إسم العائلة</label>
              <input
                type="text"
                placeholder="إسم العائلة"
                value={data.lastName}
                onChange={(e) => methods.setLastName(e.target.value)}
              />
            </div>
          </div>
          <div className="checkout-row">
            <div className="checkout-row-side">
              <label htmlFor="">رقم الهاتف</label>
              <input
                required
                pattern="^(00213|\+213|0)(5|6|7)[0-9]{8}$"
                type="text"
                placeholder="رقم الهاتف"
                value={data.phone}
                onChange={(e) => methods.setPhone(e.target.value)}
              />
            </div>
            <div className="checkout-row-side">
              <label htmlFor="">تأكيد رقم الهاتف</label>
              <input
                required
                pattern="^(00213|\+213|0)(5|6|7)[0-9]{8}$"
                type="text"
                placeholder="تأكيد رقم الهاتف"
                value={data.confirmPhone}
                onChange={(e) => methods.setConfirmPhone(e.target.value)}
              />
            </div>
          </div>
          <div className="checkout-row">
            <div className="checkout-row-side">
              <label htmlFor="">الإيمايل</label>
              <input
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                type="email"
                placeholder="الإيمايل"
                value={data.email}
                onChange={(e) => methods.setEmail(e.target.value)}
              />
            </div>
            <div className="checkout-row-side">
              <label htmlFor="">الولاية</label>
              <select
                name="wilaya"
                id=""
                value={data.wilaya}
                onChange={methods.handleWilayaChange}
              >
                <option>إختر الولاية</option>
                {WILAYAS.map((w, i) => {
                  return (
                    <option key={i} value={w.name}>
                      {w.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          {/* <div className="">
            <div className="code-promo">
              <label htmlFor="">رمز التخفيض</label>
              <input
                type="text"
                placeholder="رمز التخفيض"
                value={data.promoCode}
                onChange={(e) => methods.setPromoCode(e.target.value)}
              />
              {data.codePromoError ? (
                <span className="error-Message">{data.codePromoError}</span>
              ) : (
                <span>
                  {data.discountPercentage !== 0 ? (
                    <span className="success-Message">تم تطبيق الخصم</span>
                  ) : null}
                </span>
              )}
            </div>
          </div> */}
          <div className="checkout-address">
            <label htmlFor="">العنوان</label>
            <textarea
              placeholder="العنوان"
              name="address"
              id=""
              cols="40"
              rows="10"
              value={data.addressDetails}
              onChange={(e) => methods.setAddressDetails(e.target.value)}
            />
          </div>
        </form>
        <div className="text-center w-full xl:w-[400px]">
          <div className="checkout-details-container">
            <h3>ملخص الطلبية</h3>
            <div className="checkout-summary-row">
              <p className="checkout-summary-text">سعر المنتجات</p>
              <p className="checkout-summary-price ">
                {methods.calculateItemsPrice()}DA{" "}
                {data.discountPercentage !== 0 && (
                  <span className="checkout-summary-discount">
                    (-{data.discountPercentage}%)
                  </span>
                )}
              </p>
            </div>
            <div className="checkout-summary-row">
              <p className="checkout-summary-text">سعر التوصيل</p>
              <p className="checkout-summary-price">{data.deliveryCharge}DA</p>
            </div>
            <div className="checkout-summary-row">
              <p className="checkout-summary-text">المجموع</p>
              <p className="checkout-summary-price">
                {methods.calculateTotalPrice()}DA
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center my-4">
        <div className="checkout-btn">
          <button
            onClick={handleNextStep}
            className={
              validate() ? "opacity-40 h cursor-default" : "cursor-pointer"
            }
            disabled={validate()}
          >
            مواصلة الطلبية
          </button>
        </div>
      </div>
    </>
  );
};

export default CheckoutForm;
