import "./navbar.css";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useContext } from "react";
import CartContext from "../../CartContext";
import { axiosInstance } from "../../config";
import { AiOutlineMenu } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";
import UserContext from "../../UserContext";
import Modal from "../molecules/Modal";
import LoadingIcon from "../Icons/LoadingIcon";
import FunnelIcon from "../Icons/FunnelIcon";

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [product, setProduct] = useState("");
  const [author, setAuthor] = useState("");
  const [publisher, setPublisher] = useState("");
  const [loading, setLoading] = useState(false);
  const { token } = useContext(UserContext);

  const handleLogout = () => {
    localStorage.removeItem("token");
  };

  let navigate = useNavigate();

  const { items } = useContext(CartContext);

  const [search, setSearch] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    const fetchProducts = async () => {
      try {
        await axiosInstance
          .get(`./api/products`, {
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              search: search,
            },
          })
          .then((res) => {
            navigate("/search", { state: { products: res.data.data } });
          });
      } catch (error) {
        console.log(error);
      }
    };
    fetchProducts();
  };

  const handleAdvancedSearch = (e) => {
    e.preventDefault();
    setLoading(true);
    const fetchProducts = async (params) => {
      try {
        await axiosInstance
          .get("/api/products", {
            headers: {
              "Content-Type": "application/json",
            },
            params,
          })
          .then((res) => {
            setLoading(false);
            navigate("/search", { state: { products: res.data.data } });
            setIsOpen(false);
            setProduct("");
            setAuthor("");
            setPublisher("");
          });
      } catch (error) {
        console.log(error);
      }
    };
    const params = {};

    if (product.length > 0) params.search = product;
    if (author.length > 0) params.author = author;
    if (publisher.length > 0) params.publisher = publisher;
    fetchProducts(params);
  };

  return (
    <div className="w-full">
      {/* Top Nav */}
      <div className="flex justify-space mx-8 md:mx-20">
        <div className="py-2">
          <ul className="flex gap-4 text-sm">
            <li>
              <Link to="/about-us" className="navbar-top-link">
                من نحن؟
              </Link>
            </li>
            <li>
              <Link to="/contact-us" className="navbar-top-link">
                تواصل معنا
              </Link>
            </li>
            <li>
              <Link to="/delivery-policy" className="navbar-top-link">
                سياسة التوصيل
              </Link>
            </li>
          </ul>
        </div>
        <div className="navbar-top-left">
          {/* {!isLoggedIn ? (
            <ul>
              <li>
                <Link to="/signup" className="navbar-top-link">
                  مستخدم جديد
                </Link>
              </li>
              <li>
                <Link to="/login" className="navbar-top-link">
                  تسجيل الدخول
                </Link>
              </li>
            </ul>
          ) : (
            <ul>
              {localStorage.getItem('role') == 1 && (
                <li>
                  <Link to="/cp" className="navbar-top-link">
                    لوحة التحكم
                  </Link>
                </li>
              )}
              <li>
                <Link to="/" className="navbar-top-link">
                  <button
                    onClick={handleLogout}
                    style={{
                      backgroundColor: 'transparent',
                      border: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    تسجيل الخروج
                  </button>
                </Link>
              </li>
            </ul>
          )} */}
          {/* <ul>
            {localStorage.getItem("role") == 1 && (
              <>
                <li>
                  <Link to="/cp" className="navbar-top-link">
                    لوحة التحكم
                  </Link>
                </li>
                <li>
                  <Link to="/" className="navbar-top-link">
                    <button
                      onClick={handleLogout}
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      تسجيل الخروج
                    </button>
                  </Link>
                </li>
              </>
            )}
          </ul> */}
        </div>
      </div>
      <div className="flex items-center justify-center my-4">
        <Link to="/">
          <img src="./../static/logo_blue.png" className="w-28" alt="logo" />
        </Link>
      </div>
      {/* bottom nav */}
      <div className="border-t-[1px] border-b-[1px] h-16 flex items-center">
        <div className="flex justify-between items-center w-full mx-2 sm:mx-8 md:mx-20">
          <div className="navbar-bottom-right">
            <ul
              onClick={() => setIsMobile(true)}
              className={
                isMobile ? "navbar-hide flex gap-4" : "flex flex-col gap-2"
              }
            >
              <li onClick={() => setIsMobile(true)}>
                <Link to="/" className="navbar-top-link link-white">
                  الرئيسية
                </Link>
              </li>
              <li onClick={() => setIsMobile(true)}>
                <Link to="/books" className="navbar-top-link link-white">
                  الكتب
                </Link>
              </li>
              <li onClick={() => setIsMobile(true)}>
                <Link to="/bestsellers" className="navbar-top-link link-white">
                  الأكثر مبيعا
                </Link>
              </li>
              <li onClick={() => setIsMobile(true)}>
                <Link to="/offers" className="navbar-top-link link-white">
                  عروض خاصة
                </Link>
              </li>
            </ul>

            <button
              className="navbar-mobile-menu"
              onClick={() => setIsMobile(!isMobile)}
            >
              {isMobile ? (
                <AiOutlineMenu size={30} className="navbar-burger-menu mt-0" />
              ) : (
                <AiOutlineClose size={30} className="navbar-close-btn" />
              )}
            </button>
          </div>
          <div className="flex items-center gap-2 mt-4">
            <form
              action="/"
              className="flex items-center"
              onSubmit={handleSearch}
            >
              <div className="relative">
                <input
                  type="text"
                  placeholder="بحث"
                  className="navsearch"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <button
                  type="submit"
                  className="navbar-search-button absolute top-2 left-2"
                >
                  <BsSearch size={18} />
                </button>
              </div>
            </form>
            <button
              className="flex gap-2 relative mb-4 py-2 px-2 md:px-3 border-[1px] rounded-xl hover:bg-[#1f2f3f] hover:text-white"
              onClick={() => setIsOpen(true)}
            >
              <FunnelIcon />
              <p className="hidden md:flex">بحث متقدم</p>
            </button>
            <Link to="/cart" className="relative mb-4">
              <AiOutlineShoppingCart size={30}></AiOutlineShoppingCart>
              <span className="navbar-basket-nb absolute bottom-4 right-[5px]">
                {items.length}
              </span>
            </Link>
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} title="بحث متقدم">
        <form
          action=""
          method="post"
          className="md:w-[400px]"
          onSubmit={handleAdvancedSearch}
        >
          <div className="my-6">
            <div className="">
              <label htmlFor="">إسم الكتاب</label>
              <input
                type="text"
                value={product}
                className="w-full py-2 px-3 border-[1px] rounded-xl mt-2"
                onChange={(e) => setProduct(e.target.value)}
              />
            </div>
            <div className="mt-2">
              <label htmlFor="">الكاتب</label>
              <input
                value={author}
                type="text"
                className="w-full py-2 px-3 border-[1px] rounded-xl mt-2"
                onChange={(e) => setAuthor(e.target.value)}
              />
            </div>
            <div className="mt-2">
              <label htmlFor="">الناشر</label>
              <input
                value={publisher}
                type="text"
                className="w-full py-2 px-3 border-[1px] rounded-xl mt-2"
                onChange={(e) => setPublisher(e.target.value)}
              />
            </div>
          </div>

          <div className="mt-4 flex gap-2">
            <button
              className="flex flex-1 justify-center gap-2 py-2 px-4 bg-[#192d42] hover:bg-[#192d60] hover:opacity-80 text-white rounded-xl drop-shadow-xl"
              type="submit"
            >
              {loading && <LoadingIcon />}
              بحث
            </button>
            <button
              className="flex flex-1 justify-center py-2 px-4 bg-red-600 hover:bg-red-400 text-white rounded-xl drop-shadow-xl"
              onClick={() => setIsOpen(false)}
            >
              إلغاء
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Navbar;
