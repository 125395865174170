import React, { useState } from "react";
import PageHeader from "../../components/molecules/PageHeader";
import ControlPanel from "../../ControlPanel";
import { axiosInstance } from "../../config";
import LoadingIcon from "../../components/Icons/LoadingIcon";
import { Navigate } from "react-router-dom";

const AddPack = () => {
  const [products, setProducts] = useState([]);
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [addingPack, setAddingPack] = useState(false);
  const [packName, setPackName] = useState("");
  const [error, setError] = useState("");

  const getProducts = async () => {
    setLoading(true);
    const data = await axiosInstance.get(`./api/products`, {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        search,
      },
    });
    setProducts(data.data.data);
    setLoading(false);
  };

  const addItemIfNotExists = (itemId, itemName) => {
    const existingItem = items.find((item) => item._id === itemId);
    if (!existingItem) {
      setItems((prevArray) => [...prevArray, { _id: itemId, name: itemName }]);
    }
  };

  const removeItemById = (itemId) => {
    setItems((prevArray) => {
      const newArray = prevArray.filter((item) => item._id !== itemId);
      return newArray;
    });
  };

  const handleSubmittingPack = async (e) => {
    e.preventDefault();
    setAddingPack(true);
    try {
      await axiosInstance.post("/api/sets", {
        name: packName,
        products: items.map((item) => item._id),
      });
    } catch (error) {
      setError("error");
      setAddingPack(false);
    }
    setError("");
    setItems([]);
    setAddingPack(false);
    Navigate("/cp");
  };

  return (
    <ControlPanel>
      <div className="cp">
        <PageHeader title="إضافة حزمة" />
        <div>
          <div className="flex">
            <input
              type="text"
              className="border-2 p-2 mx-2"
              placeholder="البحث"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <button
              onClick={getProducts}
              className="bg-blue-800 hover:bg-blue-700 p-2 text-white flex gap-2 mx-2 items-center justify-center"
            >
              {loading && <LoadingIcon />}
              إبحث
            </button>
          </div>

          <div className="h-[300px] mx-2 my-2 p-2 bg-gray-100 overflow-auto">
            {products.map((product) => (
              <div
                key={product._id}
                className="bg-blue-100 my-2 p-2 flex justify-between items-center"
              >
                <p>{product.name}</p>
                <button
                  onClick={() => addItemIfNotExists(product._id, product.name)}
                  className="bg-blue-300 hover:bg-blue-400 p-1 rounded-md"
                >
                  إضافة
                </button>
              </div>
            ))}
          </div>

          <div className="">
            <h3 className="text-lg my-4 mx-2">
              المنتجات المضافة ({items.length})
            </h3>
            <div className="h-[300px] mx-2 my-2 p-2 bg-gray-100 overflow-auto">
              {items.map((item) => {
                return (
                  <div
                    key={item._id}
                    className="bg-blue-100 my-2 p-2 flex justify-between items-center"
                  >
                    <p>{item.name}</p>
                    <button
                      onClick={() => removeItemById(item._id)}
                      className="bg-red-400 hover:bg-red-500 p-1 rounded-md"
                    >
                      حذف
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <form action="/" onSubmit={handleSubmittingPack}>
          <input
            type="text"
            className="border-2 p-2 mx-2 my-4"
            placeholder="إسم الحزمة"
            value={packName}
            onChange={(e) => setPackName(e.target.value)}
          />
          <p className="text-red-600">{error}</p>
          <div className="">
            <button
              type="submit"
              className="p-2 bg-green-600 hover:bg-green-500 text-white rounded-lg mx-2 flex gap-4 items-center"
            >
              {addingPack && <LoadingIcon />}
              إضافة الحزمة
            </button>
          </div>
        </form>
      </div>
    </ControlPanel>
  );
};

export default AddPack;
