import "./pageHeader.css";

const PageHeader = ({ title }) => {
  return (
    <div className="text-xl py-6">
      <h4>{title}</h4>
    </div>
  );
};

export default PageHeader;
