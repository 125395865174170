import "./orders.css";
import ControlPanel from "../../ControlPanel";
import PageHeader from "../../components/molecules/PageHeader";
import OrdersTable from "../../components/molecules/OrdersTable";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { axiosInstance } from "../../config";
import UserContext from "../../UserContext";
import LoadingIcon from "../../components/Icons/LoadingIcon";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const { token } = useContext(UserContext);

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const { data } = await axiosInstance.get("/api/orders/", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setOrders(data.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchOrders();
  }, []);

  const handleStatusChange = async (value) => {
    try {
      setLoading(true);
      const params = {};
      if (value != "all") {
        params.status = value;
      }
      const { data } = await axiosInstance.get("/api/orders/", {
        headers: {
          "Content-Type": "application/json",
        },
        params,
      });
      setOrders(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <ControlPanel>
      <div className="pr-72 pl-8">
        <PageHeader title="الطلبيات" />
        <div className="my-2">
          <select
            onChange={(e) => handleStatusChange(e.target.value)}
            className="p-2 px-2 w-44"
            name=""
            id=""
          >
            <option value="all">كل الطلبيات</option>
            <option value="pending">pending</option>
            <option value="confirmed">confirmed</option>
            <option value="packed">packed</option>
            <option value="delilvered">delilvered</option>
            <option value="cancelled">cancelled</option>
            <option value="postponed">postponed</option>
            <option value="noAnswer">no answer</option>
            <option value="returned">returned</option>
          </select>
        </div>
        {loading ? (
          <p className="text-4xl">جاري البحث...</p>
        ) : (
          <OrdersTable orders={orders} />
        )}
        {/* <CustomersTable customers={customers} /> */}
      </div>
    </ControlPanel>
  );
};

export default Orders;
