import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/cp/Login";
import Main from "./pages/cp/Main";
import PrivateRoute from "./PrivateRoute";
import AddProduct from "./pages/cp/AddProduct";
import Products from "./pages/cp/Products";
import Customers from "./pages/cp/Customers";
import Orders from "./pages/cp/Orders";
import Categories from "./pages/cp/Categories";
import Home from "./pages/main/Home";
import Product from "./pages/main/Product";
import Signup from "./pages/main/Signup";
import { CartProvider } from "./CartContext";
import { UserProvider } from "./UserContext";
import Cart from "./pages/main/Cart";
import Checkout from "./pages/main/Checkout";
import Books from "./pages/main/Books";
import SpecialOffers from "./pages/main/SpecialOffers";
import BestSellers from "./pages/main/BestSellers";
import Search from "./pages/main/Search";
import Contactus from "./pages/main/staticPages/Contactus";
import Aboutus from "./pages/main/staticPages/Aboutus";
import DeliveryPolicy from "./pages/main/staticPages/DeliveryPolicy";
import ReturnPolicy from "./pages/main/staticPages/ReturnPolicy";
import OrderPage from "./pages/cp/OrderPage";
import AddBook from "./pages/cp/AddBook";
import NotFound from "./pages/NotFound";
import AddPack from "./pages/cp/AddPack";
import MashafPage from "./pages/main/MashafPage";
import LandingPage from "./pages/main/LandingPage";
import Comp from "./pages/main/competetions/Comp";
import Doumir from "./pages/main/competetions/Doumir";
import Sets from "./pages/cp/Sets";
import SetLanding from "./pages/main/SetLanding";
import PrivacyPolicy from "./pages/main/staticPages/PrivacyPolicy";

function App() {
  return (
    <UserProvider>
      <BrowserRouter>
        <CartProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            {/* <CpRoutes /> */}
            <Route
              path="/cp"
              element={
                <PrivateRoute>
                  <Main />
                </PrivateRoute>
              }
            />
            <Route
              path="/cp/addProduct"
              element={
                <PrivateRoute>
                  <AddProduct />
                </PrivateRoute>
              }
            />
            <Route
              path="/cp/addBook"
              element={
                <PrivateRoute>
                  <AddBook />
                </PrivateRoute>
              }
            />
            <Route
              path="/cp/addpack"
              element={
                <PrivateRoute>
                  <AddPack />
                </PrivateRoute>
              }
            />
            <Route
              path="/cp/sets"
              element={
                <PrivateRoute>
                  <Sets />
                </PrivateRoute>
              }
            />
            <Route
              path="/cp/products"
              element={
                <PrivateRoute>
                  <Products />
                </PrivateRoute>
              }
            />

            <Route
              path="/cp/customers"
              element={
                <PrivateRoute>
                  <Customers />
                </PrivateRoute>
              }
            />

            <Route
              path="/cp/orders"
              element={
                <PrivateRoute>
                  <Orders />
                </PrivateRoute>
              }
            />

            <Route
              path="/cp/order/:id"
              element={
                <PrivateRoute>
                  <OrderPage />
                </PrivateRoute>
              }
            />

            <Route
              path="/cp/categories"
              element={
                <PrivateRoute>
                  <Categories />
                </PrivateRoute>
              }
            />

            <Route path="/" element={<Home />} />
            <Route path="/books" element={<Books />} />
            <Route path="/mashaf" element={<MashafPage />} />
            <Route path="/bestsellers" element={<BestSellers />} />
            <Route path="/search" element={<Search />} />
            <Route path="/offers" element={<SpecialOffers />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/p/:productId" element={<Product />} />
            <Route path="/l/:productId" element={<LandingPage />} />
            <Route path="/s/:setId" element={<SetLanding />} />

            <Route path="/comp" element={<Comp />} />

            <Route path="/contact-us" element={<Contactus />} />
            <Route path="/about-us" element={<Aboutus />} />
            <Route path="/delivery-policy" element={<DeliveryPolicy />} />
            <Route path="/returning-items" element={<ReturnPolicy />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </CartProvider>
      </BrowserRouter>
    </UserProvider>
  );
}

export default App;
