import './input.css';

const Input = ({ placeholder, type, ...props }) => {
  return (
    <input
      className="input-component"
      type={type}
      placeholder={placeholder}
      {...props}
    />
  );
};

export default Input;
