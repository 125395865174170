import "./sidebar.css";
import { Link, useLocation } from "react-router-dom";

const SideBar = () => {
  const { pathname } = useLocation();

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <img src="../../static/logo_white.png" alt="logo" />
      </div>
      <div className="sidebar-menu">
        <h5>لوحة التحكم</h5>
        <ul className="sidebar-ul">
          <li
            className={`text-white py-2 px-8 hover:opacity-100 ${
              pathname === "/cp" ? "opacity-100" : "opacity-70"
            }`}
          >
            <Link to="/cp" className="sidebar-link-active">
              اللوحة الرئيسية
            </Link>
          </li>
          <li
            className={`text-white py-2 px-8 hover:opacity-100 ${
              pathname === "/cp/products" ? "opacity-100" : "opacity-70"
            }`}
          >
            <Link to="/cp/products" className="">
              المنتجات
            </Link>
          </li>

          <li
            className={`text-white py-2 px-8 hover:opacity-100 ${
              pathname === "/cp/sets" ? "opacity-100" : "opacity-70"
            }`}
          >
            <Link to="/cp/sets" className="">
              الحزم
            </Link>
          </li>

          <li
            className={`text-white py-2 px-8 hover:opacity-100 ${
              pathname === "/cp/addproduct" || pathname === "/cp/addbook"
                ? "opacity-100"
                : "opacity-70"
            }`}
          >
            <Link to="/cp/addproduct" className="">
              إضافة منتج
            </Link>
          </li>

          {/* <li className="sidebar-links">
            <Link to="/cp/customers" className="">
              الزبائن
            </Link>
          </li> */}
          <li
            className={`text-white py-2 px-8 hover:opacity-100 ${
              pathname === "/cp/orders" ? "opacity-100" : "opacity-70"
            }`}
          >
            <Link to="/cp/orders" className="">
              الطلبيات
            </Link>
          </li>
          {/* <li className="sidebar-links">
            <Link to="/cp/categories" className="">
              الأقسام
            </Link>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
