import "./login.css";
import Input from "../../components/atoms/Input";
import Button from "../../components/atoms/Button";
import { useContext, useState } from "react";
import { useNavigate, useLocation, Route, Navigate } from "react-router-dom";
import { axiosInstance } from "../../config";
import UserContext from "../../UserContext";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { setToken } = useContext(UserContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const from = location.state?.from?.pathname || "/cp";

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axiosInstance.post("/api/auth/login", {
        email,
        password,
      });
      //
      localStorage.setItem("token", data.data.token);
      setToken(data.data.token);
      setEmail("");
      setPassword("");
      navigate(from, { replace: true });
    } catch (error) {
      console.log(error);
    }
  };

  return !localStorage.getItem("token") ? (
    <div className="login-container">
      <div className="login-card">
        <img src="./static/logo_blue.png" alt="Image" />
        <form action="/cp" className="login-form" onSubmit={handleLogin}>
          <Input
            placeholder={"الإيمايل"}
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <Input
            placeholder={"كلمة السر"}
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <Button innerText={"تسجيل الدخول"} />
        </form>
      </div>
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export default Login;
