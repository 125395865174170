import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import UserContext from "./UserContext";
import { axiosInstance } from "./config";

const PrivateRoute = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { token } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    // console.log(token);
    if (!token) navigate("/login");
  }, []);

  return loading ? (
    <h1>جاري التحميل...</h1>
  ) : token ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
