import NavContainer from "../../../NavContainer";

const ReturnPolicy = () => {
  return (
    <NavContainer>
      <div className="static-container">
        <div className="static-content-container flex flex-col items-center justify-center text-center p-8 mt-16 mx-4 lg:mx-20 text-xl">
          <h1>سياسة الإرجاع</h1>
          <p>متأكدون أن خدماتنا ستنال إعجابكم</p>
          <p>
            في حال لم يعجبك أي شيء في مقتنياتك، يمكنك إرجاعها من دون دفع أي رسوم
          </p>
        </div>
      </div>
    </NavContainer>
  );
};

export default ReturnPolicy;
