import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../NavContainer";
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../config";
import WILAYAS from "../../wilayas";
import Loading from "../Loading";
import Swal from "sweetalert2";
import { purchaseEvent } from "../../helpers/FacebookPixel";
import CartContext from "../../CartContext";
import LoadingIcon from "../../components/Icons/LoadingIcon";
import { useDebounce } from "use-debounce";

const LandingPage = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const { orderPlaced } = useContext(CartContext);

  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState({});
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    addressDetails: "",
    wilaya: "",
  });
  const [deliveryCharge, setDeliveryCharge] = useState(400);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [codePromoValue] = useDebounce(promoCode, 1000);
  const [codePromoError, setCodePromoError] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState(0);

  const calculateItemsPrice = () => {
    const total = product.price;
    if (discountPercentage !== 0) {
      return total - (total * discountPercentage) / 100;
    }
    return total;
  };

  const checkCodePromoValidity = () => {
    if (promoCode !== "") {
      axiosInstance
        .get(`/api/promoCodes/check/${codePromoValue}`)
        .then((res) => {
          if (res.data.data.isValid === true) {
            setDiscountPercentage(res.data.data.discountPercentage);
            setCodePromoError("");
          } else {
            setCodePromoError("الرمز غير صالح");
            setDiscountPercentage(0);
          }
        })
        .catch((err) => {
          setCodePromoError("الرمز غير صالح");
          setDiscountPercentage(0);
        });
    } else {
      setCodePromoError("");
      setDiscountPercentage(0);
    }
  };

  useEffect(() => {
    checkCodePromoValidity();
  }, [codePromoValue]);

  const deliveryPrice = (wilaya) => {
    for (let i = 0; i < WILAYAS.length; i++) {
      if (WILAYAS[i].name === wilaya) {
        return WILAYAS[i].price;
      }
    }
  };

  const handleWilayaChange = (e) => {
    setData((prev) => {
      return { ...prev, wilaya: e.target.value };
    });
    const deliveryPriced = deliveryPrice(e.target.value);
    setDeliveryCharge(deliveryPriced);
  };

  useEffect(() => {
    setLoading(true);
    const fetchProduct = async () => {
      setLoading(true);
      try {
        const product = await axiosInstance.get(`/api/products/${productId}`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setProduct(product.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchProduct();
  }, [productId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      await axiosInstance.post("/api/orders/", {
        clientFirstName: data.firstName,
        clientLastName: data.lastName,
        clientPhoneNumber: data.phone,
        products: [{ id: productId, quantity: 1 }],
        addressDetails: data.addressDetails,
        wilaya: data.wilaya,
        origin: "web",
        // promoCode: codePromoValue,
      });
      Swal.fire({
        position: "top",
        icon: "success",
        title: "تم تأكيد الطلبية",
        text: "سنتواصل معك قريبا",
        showConfirmButton: false,
        timer: 1250,
      });
      purchaseEvent(
        [{ id: productId, quantity: 1 }],
        product.price + deliveryCharge
      );
      setSubmitting(false);
      setError("");
      orderPlaced();
      navigate("/", { replace: true });
    } catch (error) {
      setError("حدث خطأ ما، جرب مرة أخرى");
      console.log(error);
      setSubmitting(false);
    }
  };

  return (
    <NavContainer>
      <>
        <div className="bg-[#192d42] p-4 sm:p-8 sm:flex items-center justify-around">
          <div className="flex justify-center mb-8 sm:mb-0">
            <img className="w-44" src="./../static/logo_white.png" alt="" />
          </div>

          <div className="text-center text-white ">
            <p className="font-bold text-2xl mb-4">متجر نقرة للكتب</p>
            <p className="mb-3">
              يكفي أن تتبع الخطوات الواردة لتصل طلبيتك إلينا، وسنتكفل نحن
              بالباقي...
            </p>
            <p className="mb-3">
              لدينا فريق كامل يتابع وينفذ ويتواصل معكم إلى حين استلام الكتاب...
            </p>
            <p className="mb-3">
              شركات التوصيل المتعاقدة معنا تقوم بتوصيل طلبيتك إلى باب بيتك...
            </p>
            <p className="mb-3">الدفع يكون عند استلامك للطلبية...</p>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row gap-2 py-6 px-4 mt-4">
          {loading ? (
            <Loading />
          ) : (
            <div className="flex-1 px-4">
              <div className="bg-blue-100 rounded-lg p-4">
                <form onSubmit={handleSubmit} action="" method="post">
                  <p className="font-bold text-center mb-2">
                    للطلب إملأ الاستمارة
                  </p>
                  <div className="">
                    <label htmlFor="name">الإسم الأول</label>
                    <input
                      className="border-solid block mt-1 py-1 px-2 rounded-xl w-full"
                      type="text"
                      name="name"
                      placeholder="الإسم الأول"
                      value={data.firstName}
                      onChange={(e) =>
                        setData((prev) => {
                          return { ...prev, firstName: e.target.value };
                        })
                      }
                    />
                  </div>
                  <div className="mt-4">
                    <label htmlFor="lastname">اللقب</label>
                    <input
                      className="border-solid block mt-1 py-1 px-2 rounded-xl w-full"
                      type="text"
                      name="lastname"
                      placeholder="اللقب"
                      value={data.lastName}
                      onChange={(e) =>
                        setData((prev) => {
                          return { ...prev, lastName: e.target.value };
                        })
                      }
                    />
                  </div>
                  <div className="mt-4">
                    <label htmlFor="phone">رقم الهاتف</label>
                    <input
                      className="border-solid block mt-1 py-1 px-2 rounded-xl w-full"
                      type="text"
                      name="phone"
                      placeholder="رقم الهاتف"
                      value={data.phone}
                      onChange={(e) =>
                        setData((prev) => {
                          return { ...prev, phone: e.target.value };
                        })
                      }
                    />
                  </div>
                  <div className="mt-4">
                    <label htmlFor="wilaya">
                      الولاية (سعر التوصيل حسب الولاية)
                    </label>
                    <select
                      className="border-solid block mt-1 py-1 px-2 rounded-xl w-full"
                      name="wilaya"
                      id=""
                      value={data.wilaya}
                      onChange={handleWilayaChange}
                    >
                      <option>إختر الولاية</option>
                      {WILAYAS.map((w, i) => {
                        return (
                          <option key={i} value={w.name}>
                            {w.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="mt-4">
                    <label htmlFor="addressDetails">الدائرة</label>
                    <input
                      className="border-solid block mt-1 py-1 px-2 rounded-xl w-full"
                      type="text"
                      name="addressDetails"
                      placeholder="الدائرة"
                      value={data.addressDetails}
                      onChange={(e) =>
                        setData((prev) => {
                          return { ...prev, addressDetails: e.target.value };
                        })
                      }
                    />
                  </div>
                  {/* promo code container */}
                  {/* <div className="mt-4 flex-col flex space-y-2">
                    <label htmlFor="" className="addressDetails">
                      رمز التخفيض
                    </label>
                    <input
                      type="text"
                      className="border-solid block mt-1 py-1 px-2 rounded-xl w-full"
                      placeholder="رمز التخفيض"
                      value={promoCode}
                      onChange={(e) => setPromoCode(e.target.value)}
                    />
                    {codePromoError ? (
                      <span className="text-red-700 text-md">
                        {codePromoError}
                      </span>
                    ) : (
                      <span>
                        {discountPercentage !== 0 ? (
                          <span className="text-green-800 text-md">
                            تم تطبيق الخصم
                          </span>
                        ) : null}
                      </span>
                    )}
                  </div> */}
                  {/* end promo code */}
                  <p className="text-red-400 mt-2">{error}</p>
                  <div className="bg-blue-300 text-blue-900 font-bold mt-4 rounded-xl p-2">
                    <p>
                      السعر:{" "}
                      {discountPercentage !== 0 && (
                        <span className="checkout-summary-discount">
                          ({discountPercentage}%-)
                        </span>
                      )}{" "}
                      {calculateItemsPrice()}دج
                    </p>
                    <p className="mt-2">
                      سعر التوصيل: {deliveryCharge}
                      دج
                    </p>
                    <p className="mt-2">
                      السعر الإجمالي: {calculateItemsPrice() + deliveryCharge}دج
                    </p>
                  </div>
                  <button
                    className="text-center flex gap-2 justify-center w-full bg-green-500 rounded-xl mt-4 p-2 text-white hover:bg-green-600"
                    type="submit"
                    disabled={submitting}
                  >
                    {submitting ? <LoadingIcon /> : null}
                    الطلب
                  </button>
                </form>
              </div>
            </div>
          )}

          <div className="text-center flex flex-col items-center gap-3 flex-1 mb-8 md:mb-0">
            <h1 className="font-bold text-xl">{product.name}</h1>
            <p className="">{product.details}</p>
            <img
              src={product.img}
              alt="product"
              className="w-[200px] h-fit rounded-xl drop-shadow-sm"
            />
          </div>
        </div>
      </>
    </NavContainer>
  );
};

export default LandingPage;
