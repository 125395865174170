import ControlPanel from "../../ControlPanel";
import PageHeader from "../../components/molecules/PageHeader";
import { Link } from "react-router-dom";

const AddProduct = () => {
  return (
    <ControlPanel>
      <div className="pr-72">
        <PageHeader title={"إضافة منتج"} />
        <div className="flex gap-4 my-2">
          <Link to="/cp/addbook" className="btn-link">
            إضافة كتاب جديد
          </Link>
          <Link to="/cp/addpack" className="btn-link">
            إضافة حزمة
          </Link>
        </div>
      </div>
    </ControlPanel>
  );
};

export default AddProduct;
