export const addToCartEvent = (content_name, ids, value) => {
  window.fbq("track", "AddToCart", {
    content_name,
    content_category: "Book",
    content_type: "product",
    content_ids: ids,
    value,
    currency: "DZD",
  });
};

export const purchaseEvent = (contents, value) => {
  window.fbq("track", "Purchase", {
    currency: "DZD",
    value,
    content_category: "Book",
    content_type: "product",
    contents,
  });
};
