import "./productsTable.css";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { axiosInstance } from "../../config";
import UserContext from "../../UserContext";
import { useContext, useState } from "react";
import Modal from "./Modal";
import LabeledInput from "../atoms/LabeledInput";
import Select from "../atoms/Select";
import LoadingIcon from "../Icons/LoadingIcon";

const ProductsTable = ({ products }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentProduct, setCurrentProduct] = useState({});
  const { token } = useContext(UserContext);

  const validate = () => {
    if (
      !currentProduct.author ||
      !currentProduct.name ||
      !currentProduct.subject ||
      !currentProduct.publisher ||
      currentProduct.quantity < 0 ||
      currentProduct.price < 0 ||
      !currentProduct.paper ||
      !currentProduct.language ||
      !currentProduct.details
    )
      return true;
    return false;
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      name: currentProduct.name,
      subject: currentProduct.subject,
      author: currentProduct.author,
      publisher: currentProduct.publisher,
      quantity: currentProduct.quantity,
      price: currentProduct.price,
      sale: currentProduct.sale,
      priceBeforeSale: currentProduct.priceBeforeSale,
      paper: currentProduct.paper,
      language: currentProduct.language,
      details: currentProduct.details,
    };
    await axiosInstance.put(`/api/products/${currentProduct._id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    setLoading(false);
    setIsOpen(false);
    Swal.fire({
      position: "center",
      icon: "success",
      title: "تم التعديل بنجاح",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "حذاري... هل أنت متأكد من أنك تريد حذف المنتج؟",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "نعم",
      cancelButtonText: "لا",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axiosInstance.delete(`/api/products/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        Swal.fire("تم الحذف!");
      }
    });
  };

  return (
    <div className="products-table">
      <div className="bookslist-container">
        <table className="productstable-table">
          <thead>
            <tr>
              <th>الإسم</th>
              <th>السعر</th>
              <th>عدد الطلبيات</th>
              <th>الكمية المتبقية</th>
              <th>تاريخ الإضافة</th>
              <th>تعديل</th>
            </tr>
          </thead>

          <tbody>
            {products.map((product) => {
              return (
                <tr key={product._id}>
                  <td>
                    <Link to={`/p/${product._id}`} className="link table-link">
                      {product.name}
                    </Link>
                  </td>
                  <td>{product.price}DA</td>
                  <td>{product.orderTimes}</td>
                  <td>{product.quantity}</td>
                  <td>{new Date(product.createdAt).toDateString()}</td>
                  <td>
                    <button
                      className="table-btn-edit"
                      onClick={() => {
                        setCurrentProduct(product);
                        setIsOpen(true);
                      }}
                    >
                      تعديل
                    </button>
                    <button
                      className="table-btn-delete"
                      onClick={() => handleDelete(product._id)}
                    >
                      حذف
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} title="تعديل المنتج">
        <form action="/" className="px-5" onSubmit={handleEditSubmit}>
          <LabeledInput
            name="name"
            label="عنوان الكتاب:"
            type="text"
            value={currentProduct.name || ""}
            placeholder="عنوان الكتاب"
            onChange={(e) => {
              setCurrentProduct({
                ...currentProduct,
                name: e.target.value,
              });
            }}
          />
          <Select
            title="الصنف:"
            values={[
              { value: "", option: "إختر صنفا للكتاب" },
              { value: "كتب أخرى", option: "كتب أخرى" },
              { value: "فكر", option: "فكر" },
              {
                value: "علوم القرآن والتفسير",
                option: "علوم القرآن والتفسير",
              },
              { value: "الفقه وأصوله", option: "الفقه وأصوله" },
              { value: "التاريخ", option: "التاريخ" },
              { value: "السيرة", option: "السيرة" },
              { value: "العقيدة", option: "العقيدة" },
              { value: "علوم الحديث", option: "علوم الحديث" },
              { value: "التربية", option: "التربية" },
              { value: "الاجتماع", option: "الاجتماع" },
              { value: "الفلسفة", option: "الفلسفة" },
              { value: "القانون", option: "القانون" },
              { value: "السياسة", option: "السياسة" },
              { value: "النحو والصرف", option: "النحو والصرف" },
              { value: "القانون", option: "القانون" },
              { value: "البلاغة", option: "البلاغة" },
              { value: "لسانيات", option: "لسانيات" },
              { value: "الرواية والقصة", option: "الرواية والقصة" },
              { value: "الشعر", option: "الشعر" },
              { value: "إقتصاد", option: "إقتصاد" },
              { value: "فرنسية", option: "فرنسية" },
              { value: "روايات", option: "روايات" },
              { value: "مصاحف", option: "مصاحف" },
              { value: "تنمية بشرية", option: "تنمية بشرية" },
            ]}
            value={currentProduct.subject || ""}
            onChange={(e) => {
              setCurrentProduct({
                ...currentProduct,
                subject: e.target.value,
              });
            }}
          />
          <LabeledInput
            name="publisher"
            label="الناشر"
            type="text"
            value={currentProduct.publisher || ""}
            placeholder="الناشر"
            onChange={(e) => {
              setCurrentProduct({
                ...currentProduct,
                publisher: e.target.value,
              });
            }}
          />
          <LabeledInput
            name="quantity"
            label="الكمية"
            type="number"
            value={currentProduct.quantity || ""}
            placeholder="الكمية"
            onChange={(e) => {
              setCurrentProduct({
                ...currentProduct,
                quantity: e.target.value,
              });
            }}
          />
          <LabeledInput
            name="price"
            label="السعر"
            type="number"
            value={currentProduct.price || ""}
            placeholder="السعر"
            onChange={(e) => {
              setCurrentProduct({
                ...currentProduct,
                price: e.target.value,
              });
            }}
          />
          <LabeledInput
            name="price"
            label="عليه تخفيض"
            type="checkbox"
            value={currentProduct.sale || ""}
            checked={currentProduct.sale}
            // placeholder="السعر"
            onChange={(e) => {
              setCurrentProduct({
                ...currentProduct,
                sale: !currentProduct.sale,
              });
            }}
          />

          {currentProduct.sale && (
            <LabeledInput
              name="priceBefore"
              label="السعر قبل التخفيض"
              type="number"
              value={currentProduct.priceBeforeSale || ""}
              // placeholder="السعر"
              onChange={(e) => {
                setCurrentProduct({
                  ...currentProduct,
                  priceBeforeSale: e.target.value,
                });
              }}
            />
          )}
          <Select
            title="نوعية الورق:"
            values={[
              { value: "", option: "إختر نوع الورق" },
              { value: "ورق أبيض", option: "ورق أبيض" },
              { value: "ورق شاموا", option: "ورق شاموا" },
              { value: "ورق كرتوني", option: "ورق كرتوني" },
            ]}
            value={currentProduct.paper || ""}
            onChange={(e) => {
              setCurrentProduct({
                ...currentProduct,
                paper: e.target.value,
              });
            }}
          />
          <Select
            title="إختر اللغة:"
            values={[
              { value: "", option: "إختر اللغة" },
              { value: "العربية", option: "العربية" },
              { value: "الفرنسية", option: "الفرنسية" },
              { value: "الإنجليزية", option: "الإنجليزية" },
            ]}
            value={currentProduct.language || ""}
            onChange={(e) => {
              setCurrentProduct({
                ...currentProduct,
                language: e.target.value,
              });
            }}
          />
          <textarea
            className="drop-shadow-sm border-[1px] focus:outline-none block py-2 px-4 my-2	rounded-lg w-80 border-slate-200 placeholder-slate-400 contrast-more:border-slate-400 contrast-more:placeholder-slate-500"
            name="details"
            id=""
            cols="30"
            rows="10"
            placeholder="معلومات حول الكتاب"
            value={currentProduct.details || ""}
            onChange={(e) => {
              setCurrentProduct({
                ...currentProduct,
                details: e.target.value,
              });
            }}
          />

          <div className="my-6 flex gap-2">
            <button
              className={`flex gap-2 py-2 px-4 bg-[#192d42] hover:bg-[#192d60] text-white rounded-xl drop-shadow-xl 
                ${validate() || loading ? "opacity-40" : ""}`}
              type="submit"
              disabled={loading || validate()}
            >
              {loading && <LoadingIcon />}
              تعديل
            </button>
            <button
              className={`py-2 px-4 bg-red-600 hover:bg-red-400 text-white rounded-xl drop-shadow-xl `}
              onClick={() => setIsOpen(false)}
            >
              إلغاء
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default ProductsTable;
