import "./home.css";
import NavContainer from "../../NavContainer";
import Show from "../../components/organisms/Show";
import { useState, useEffect } from "react";
import { axiosInstance } from "../../config";
import Loading from "../Loading";
import Pagination from "../../components/organisms/Pagination";

const Home = () => {
  const [products, setProducts] = useState([]);
  const [bestSellers, setBestSellers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const { data } = await axiosInstance
          .get("../api/products/", {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .finally(() => {
            setLoading(false);
          });
        setProducts(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    const fetchBestSellers = async () => {
      try {
        setLoading(true);
        await axiosInstance
          .get("../api/products/", {
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              sort: "orderTimes",
            },
          })
          .then((res) => {
            setBestSellers(res.data.data);
            setLoading(false);
          });
      } catch (error) {
        console.log(error);
      }
    };
    fetchProducts();
    fetchBestSellers();
  }, []);

  return (
    <NavContainer>
      {loading ? (
        <>
          <Loading />
        </>
      ) : (
        <div className="mx-2 md:mx-20 min-h-screen">
          <Show
            title={"الأكثر مبيعا"}
            data={bestSellers.slice(0, 8)}
            link="/bestsellers"
          />

          <Show
            title={"عروض خاصة"}
            data={products
              .filter((product) => {
                return product.sale === true;
              })
              .slice(0, 8)}
            link="/offers"
          />

          <Pagination data={products} />
          {/* Slider */}
          {/* 3 box images of offers */}

          {/* <Show title={"إخترنا لكم"} data={products.slice(0, 5)} link="/" /> */}

          {/* 1000*50 image */}
          {/* Other categories */}
          {/* Hot sale => Vertical Category */}
        </div>
      )}
    </NavContainer>
  );
};

export default Home;
