import "./products.css";
import ControlPanel from "../../ControlPanel";
import PageHeader from "../../components/molecules/PageHeader";
import ProductsTable from "../../components/molecules/ProductsTable";
import { useState, useEffect, useContext } from "react";
import { axiosInstance } from "../../config";
import UserContext from "../../UserContext";

const Products = () => {
  const [products, setProducts] = useState([]);
  const { token } = useContext(UserContext);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const { data } = await axiosInstance.get("/api/products/all", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setProducts(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchProducts();
  }, [token]);

  return (
    <ControlPanel>
      <div className="pr-72 pl-8">
        <PageHeader title="المنتجات" />
        <ProductsTable products={products} />
      </div>
    </ControlPanel>
  );
};

export default Products;
