import React, { useState } from "react";
import { useEffect } from "react";
import LabeledInput from "../../components/atoms/LabeledInput";
import PageHeader from "../../components/molecules/PageHeader";
import ControlPanel from "../../ControlPanel";
import Select from "../../components/atoms/Select";
import { axiosInstance } from "../../config";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// There is no categoryName yet
const AddBook = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [author, setAuthor] = useState("");
  const [publisher, setPublisher] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [price, setPrice] = useState(0);
  const [sale, setSale] = useState(false);
  const [priceBefore, setPriceBefore] = useState(0);
  const [paper, setPaper] = useState("");
  const [language, setLanguage] = useState("");
  const [details, setDetails] = useState("");
  const [img, setImg] = useState(null);
  const [loading, setLoading] = useState(false);

  const validate = () => {
    if (
      !author ||
      !name ||
      !subject ||
      !publisher ||
      quantity <= 0 ||
      price < 0 ||
      !paper ||
      !language ||
      !details ||
      !img
    )
      return true;
    return false;
  };

  const handleAddBook = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/s3/`,
        {
          filename: img.name,
          filetype: img.type,
        }
      );
      const res2 = await axios.put(res.data.url, img, {
        headers: {
          "Content-Type": img.type,
        },
      });

      const imgURL = res2.request.responseURL.split("?")[0];
      const book = {
        name,
        img: imgURL,
        price,
        sale,
        kind: "Book",
        details,
        isVisible: true,
        quantity,
        author,
        publisher,
        subject,
        language,
        paper,
        categoryName: "Book",
      };
      if (sale) book.priceBeforeSale = priceBefore;

      await axiosInstance.post("/api/products/", book, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setLoading(false);
      navigate("/cp/addproduct");
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <ControlPanel>
      <div className="cp">
        <PageHeader title="إضافة كتاب" />
        <form action="/" className="px-5" onSubmit={handleAddBook}>
          <LabeledInput
            name="name"
            label="عنوان الكتاب:"
            type="text"
            value={name}
            placeholder="عنوان الكتاب"
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <Select
            title="الصنف:"
            values={[
              { value: "", option: "إختر صنفا للكتاب" },
              { value: "كتب أخرى", option: "كتب أخرى" },
              { value: "فكر", option: "فكر" },
              {
                value: "علوم القرآن والتفسير",
                option: "علوم القرآن والتفسير",
              },
              { value: "الفقه وأصوله", option: "الفقه وأصوله" },
              { value: "التاريخ", option: "التاريخ" },
              { value: "السيرة", option: "السيرة" },
              { value: "العقيدة", option: "العقيدة" },
              { value: "علوم الحديث", option: "علوم الحديث" },
              { value: "التربية", option: "التربية" },
              { value: "الاجتماع", option: "الاجتماع" },
              { value: "الفلسفة", option: "الفلسفة" },
              { value: "القانون", option: "القانون" },
              { value: "السياسة", option: "السياسة" },
              { value: "النحو والصرف", option: "النحو والصرف" },
              { value: "القانون", option: "القانون" },
              { value: "البلاغة", option: "البلاغة" },
              { value: "لسانيات", option: "لسانيات" },
              { value: "الرواية والقصة", option: "الرواية والقصة" },
              { value: "الشعر", option: "الشعر" },
              { value: "إقتصاد", option: "إقتصاد" },
              { value: "فرنسية", option: "فرنسية" },
              { value: "روايات", option: "روايات" },
              { value: "مصاحف", option: "مصاحف" },
              { value: "تنمية بشرية", option: "تنمية بشرية" },
            ]}
            value={subject}
            onChange={(e) => {
              setSubject(e.target.value);
            }}
          />
          {/* category */}
          <LabeledInput
            name="author"
            label="الكاتب"
            type="text"
            value={author}
            placeholder="الكاتب"
            onChange={(e) => {
              setAuthor(e.target.value);
            }}
          />
          <LabeledInput
            name="publisher"
            label="الناشر"
            type="text"
            value={publisher}
            placeholder="الناشر"
            onChange={(e) => {
              setPublisher(e.target.value);
            }}
          />
          <LabeledInput
            name="quantity"
            label="الكمية"
            type="number"
            value={quantity}
            placeholder="الكمية"
            onChange={(e) => {
              setQuantity(e.target.value);
            }}
          />
          <LabeledInput
            name="price"
            label="السعر"
            type="number"
            value={price}
            placeholder="السعر"
            onChange={(e) => {
              setPrice(e.target.value);
            }}
          />
          <LabeledInput
            name="price"
            label="عليه تخفيض"
            type="checkbox"
            value={sale}
            // placeholder="السعر"
            onChange={() => {
              setSale(!sale);
            }}
          />
          {sale && (
            <LabeledInput
              name="priceBefore"
              label="السعر قبل التخفيض"
              type="number"
              value={priceBefore}
              // placeholder="السعر"
              onChange={(e) => {
                setPriceBefore(e.target.value);
              }}
            />
          )}
          <Select
            title="نوعية الورق:"
            values={[
              { value: "", option: "إختر نوع الورق" },
              { value: "ورق أبيض", option: "ورق أبيض" },
              { value: "ورق شاموا", option: "ورق شاموا" },
              { value: "ورق كرتوني", option: "ورق كرتوني" },
            ]}
            value={paper}
            onChange={(e) => {
              setPaper(e.target.value);
            }}
          />

          <Select
            title="إختر اللغة:"
            values={[
              { value: "", option: "إختر اللغة" },
              { value: "العربية", option: "العربية" },
              { value: "الفرنسية", option: "الفرنسية" },
              { value: "الإنجليزية", option: "الإنجليزية" },
            ]}
            value={language}
            onChange={(e) => {
              setLanguage(e.target.value);
            }}
          />
          <textarea
            className="drop-shadow-sm focus:outline-none block py-2 px-4 my-4 rounded-lg w-full border-slate-200 placeholder-slate-400 contrast-more:border-slate-400 contrast-more:placeholder-slate-500"
            name="details"
            id=""
            cols="30"
            rows="10"
            placeholder="معلومات حول الكتاب"
            value={details}
            onChange={(e) => {
              setDetails(e.target.value);
            }}
          />
          <input
            type="file"
            className="block w-full text-sm text-slate-500 file:mr-4 file:my-4 file:py-2 file:px-4 file:rounded-full file:border-0
      file:text-sm file:font-semibold
      file:bg-blue-50 file:text-blue-700
      hover:file:bg-blue-100"
            name="image"
            accept=".png, .jpg, .jpeg"
            onChange={(e) => {
              setImg(e.target.files[0]);
            }}
          />
          {loading && <p className="mt-4">جاري الإضافة...</p>}
          <button
            className={`mt-12 mb-16  py-2 px-4 bg-[#192d42] hover:bg-[#192d60] text-white rounded-xl drop-shadow-xl 
                ${validate() || loading ? "opacity-40" : ""}`}
            type="submit"
            disabled={loading || validate()}
          >
            إضافة كتاب جديد
          </button>
        </form>
      </div>
    </ControlPanel>
  );
};

export default AddBook;
