import "./product.css";
import NavContainer from "../../NavContainer";
import { useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import CartContext from "../../CartContext";
import { axiosInstance } from "../../config";
import Loading from "../Loading";
import Suggestions from "../../components/organisms/Suggestions";

const Product = () => {
  const { productId } = useParams();
  const { addToCart } = useContext(CartContext);

  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState({});
  const [suggestedProducts, setSuggestedProducts] = useState([]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const product = await axiosInstance.get(
          `../api/products/${productId}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const suggestedProducts = await axiosInstance.get(
          `../api/suggest/${productId}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setProduct(product.data.data);
        setSuggestedProducts(suggestedProducts.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchProduct();
  }, [productId]);

  return (
    <NavContainer>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="product-container">
            <div className="lg:flex gap-4 p-2 sm:p-8">
              <div className=" mb-6 lg:mb-0 flex justify-center">
                <img
                  src={product.img}
                  alt="product"
                  className="w-[300px] h-fit"
                />
              </div>
              <div className="mt-6 md:mt-0 bg-gray-100 p-4 rounded-xl overflow-y-scroll w-full">
                <h1 className="text-2xl font-normal">{product.name}</h1>
                <div className="product-price">
                  {product.sale && (
                    <span className="price-before">
                      {product.priceBeforeSale}دج
                    </span>
                  )}

                  <span className="price">
                    &nbsp;&nbsp;&nbsp;
                    {product.price}دج
                  </span>
                </div>
                <div className="flex gap-2">
                  <input
                    className="product-info-btn bg-[#034748]"
                    type="button"
                    value="إضافة إلى السلة"
                    onClick={() => {
                      addToCart(
                        product.name,
                        product.price,
                        1,
                        product.img,
                        product._id
                      );
                    }}
                  />
                  <a
                    href="https://api.whatsapp.com/send/?phone=213561678770&text&app_absent=0"
                    target="_blank"
                    className="product-info-btn text-center text-[#034748] hover:bg-[#034748] hover:text-white"
                  >
                    تواصل معنا عبر الواتساب
                  </a>
                </div>

                <p className="text-2xl mb-6">معلومات حول المنتج</p>
                {product.productKind === "Book" && (
                  <>
                    <p className="product-details-p">
                      الناشر: {product.publisher}
                    </p>
                    <p className="product-details-p">
                      الكاتب: {product.author}
                    </p>
                    <p className="product-details-p">
                      نوعية الورق: {product.paper}
                    </p>
                    <p className="product-details-p">
                      لغة الكتاب: {product.language}
                    </p>
                    {product.quantity === 1 && (
                      <p className="product-details-p">آخر نسخة</p>
                    )}
                  </>
                )}
                <p className="product-details-p">معلومات عن المنتج:</p>
                <p className="product-details-p">{product.details}</p>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="gap-4 p-2 sm:p-8">
              <Suggestions title="كتب ذات صلة" data={suggestedProducts} />
            </div>
          </div>
        </>
      )}
    </NavContainer>
  );
};

export default Product;
