import "./ordersTable.css";
import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import Modal from "./Modal";
import Select from "../atoms/Select";
import LoadingIcon from "../Icons/LoadingIcon";
import { axiosInstance } from "../../config";
import UserContext from "../../UserContext";

const OrdersTable = ({ orders }) => {
  const [currentOrder, setCurrentOrder] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { token } = useContext(UserContext);

  const statusClass = (order) => {
    switch (order.status) {
      case "confirmed":
        return "text-blue-700";
      case "packed":
        return "text-green-300";
      case "delilvered":
        return "text-green-700";
      case "returned":
        return "text-yellow-600";
      case "cancelled":
        return "text-red-600";
      case "postponed":
        return "text-red-200";
      case "noAnswer":
        return "text-blue-200";
      default:
        return "text-fuchsia-700";
    }
  };

  const handleChangeStatus = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axiosInstance.put(
      `/api/orders/${currentOrder._id}`,
      { status: currentOrder.status },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    setLoading(false);
    setCurrentOrder((prev) => {
      return { ...prev, status: currentOrder.status };
    });
    setIsOpen(false);
  };

  const downloadBilling = async (id) => {
    await axiosInstance
      .get(`/api/orders/download/${id}`, {
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "order.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
        return data;
      });
  };

  return (
    <div className="products-table">
      <div className="bookslist-container">
        <table className="productstable-table">
          <thead>
            <tr>
              <th>رقم الطلبية</th>
              <th>الزبون</th>
              <th>الهاتف</th>
              <th>الولاية</th>
              <th>السعر الإجمالي</th>
              <th>التاريخ</th>
              <th>الوضعية</th>
              <th>المزيد</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => {
              return (
                <tr key={order._id}>
                  <td>
                    <Link
                      to={`/cp/order/${order._id}`}
                      className="link table-link"
                    >
                      {order._id}
                    </Link>
                  </td>
                  <td>{order.clientLastName + " " + order.clientFirstName}</td>
                  <td>{order.clientPhoneNumber}</td>
                  <td>{order.address.wilaya}</td>
                  <td>{order.totalPrice}DA</td>
                  <td>
                    {new Date(order.createdAt).toDateString("fr-FR", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </td>
                  <td className={statusClass(order)}>{order.status}</td>
                  <td className="flex items-center justify-center gap-2">
                    <button
                      className="table-btn-edit bg-blue-700"
                      onClick={() => {
                        downloadBilling(order._id);
                      }}
                    >
                      الوصل
                    </button>
                    <button
                      className="table-btn-edit"
                      onClick={() => {
                        setCurrentOrder(order);
                        setIsOpen(true);
                      }}
                    >
                      تعديل
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} title="تعديل الوضعية">
        <form action="" method="post" onSubmit={handleChangeStatus}>
          <Select
            title="وضعية الطلبية:"
            values={[
              { value: "pending", option: "Pending" },
              { value: "confirmed", option: "Confirmed" },
              { value: "packed", option: "Packed" },
              { value: "delilvered", option: "Delilvered" },
              { value: "cancelled", option: "Cancelled" },
              { value: "postponed", option: "Postponed" },
              { value: "noAnswer", option: "No Answer" },
              { value: "returned", option: "Returned" },
            ]}
            value={currentOrder.status || ""}
            onChange={(e) => {
              setCurrentOrder({ ...currentOrder, status: e.target.value });
            }}
          />
          <div className="mt-4 flex gap-2">
            <button
              className={`flex gap-2 py-2 px-4 bg-[#192d42] hover:bg-[#192d60] hover:opacity-80 text-white rounded-xl drop-shadow-xl 
                ${loading ? "opacity-40" : ""}`}
              type="submit"
              disabled={loading}
            >
              {loading && <LoadingIcon />}
              تعديل
            </button>
            <button
              className={`py-2 px-4 bg-red-600 hover:bg-red-400 text-white rounded-xl drop-shadow-xl `}
              onClick={() => setIsOpen(false)}
            >
              إلغاء
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default OrdersTable;
