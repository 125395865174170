import "./main.css";
import ControlPanel from "../../ControlPanel";
import PageHeader from "../../components/molecules/PageHeader";
import { useContext, useState, useEffect } from "react";
import { axiosInstance } from "../../config";
import UserContext from "../../UserContext";

const Main = () => {
  const [ordersStats, setOrderStats] = useState({});
  const [monthlyStats, setMonthlyStats] = useState({});
  const [wilayas, setWilayas] = useState([]);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const handleMonthChange = async (e) => {
    setSelectedMonth(parseInt(e.target.value));
    try {
      const { data } = await axiosInstance.get(
        `/api/stats/month?month=2023-${e.target.value}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setMonthlyStats(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  // const handleYearChange = (e) => {
  //   setSelectedYear(parseInt(e.target.value));
  // };
  useEffect(() => {
    const getPendingCount = async () => {
      try {
        const { data } = await axiosInstance.get("/api/stats/orders", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setOrderStats(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    const getMonthlyStats = async () => {
      try {
        const { data } = await axiosInstance.get(
          `/api/stats/month?month=2023-${selectedMonth}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setMonthlyStats(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    const getWilayas = async () => {
      try {
        const { data } = await axiosInstance.get("/api/stats/wilayas", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setWilayas(data.data.addressCount);
        console.log(data.data.addressCount);
      } catch (error) {
        console.log(error);
      }
    };
    getMonthlyStats();
    getPendingCount();
    getWilayas();
  }, []);

  return (
    <ControlPanel>
      <div className="pr-72 pl-8">
        <PageHeader title="لوحة التحكم" />
        <div className="flex gap-2 flex-wrap">
          <div className="bg-gray-200 w-44 flex flex-col gap-4 justify-center items-center p-4">
            <p>عدد الطلبيات المعلقة</p>
            <p className="font-bold text-xl text-red-600">
              {ordersStats.pendingCount}
            </p>
          </div>
          <div className="bg-gray-200 w-44 flex flex-col gap-4 justify-center items-center p-4">
            <p>عدد الطلبيات الجاهزة</p>
            <p className="font-bold text-xl text-red-600">
              {ordersStats.packedCount}
            </p>
          </div>
          <div className="bg-gray-200 w-44 flex flex-col gap-4 justify-center items-center p-4">
            <p>عدد الطلبيات الناجحة</p>
            <p className="font-bold text-xl text-red-600">
              {ordersStats.deliveredCount}
            </p>
          </div>
          <div className="bg-gray-200 w-44 flex flex-col gap-4 justify-center items-center p-4">
            <p>عدد الطلبيات الملغية</p>
            <p className="font-bold text-xl text-red-600">
              {ordersStats.cancelledCount}
            </p>
          </div>
        </div>
        <PageHeader title="التقارير الشهرية" />
        <select
          value={selectedMonth}
          onChange={handleMonthChange}
          className="p-2 my-2"
        >
          {Array.from({ length: 12 }, (_, i) => (
            <option key={i + 1} value={i + 1}>
              {new Date(currentYear, i, 1).toLocaleString("default", {
                month: "long",
              })}
            </option>
          ))}
        </select>
        <div className="flex gap-2 flex-wrap">
          <div className="bg-gray-200 w-44 flex flex-col gap-4 justify-center items-center p-4">
            <p>عدد الطلبيات</p>
            <p className="font-bold text-xl text-red-600">
              {monthlyStats.orderCount}
            </p>
          </div>

          <div className="bg-gray-200 w-44 flex flex-col gap-4 justify-center items-center p-4">
            <p>عدد الطلبيات الناجحة</p>
            <p className="font-bold text-xl text-red-600">
              {monthlyStats.delivered}
            </p>
          </div>

          <div className="bg-gray-200 w-44 flex flex-col gap-4 justify-center items-center p-4">
            <p>عدد الطلبيات العائدة</p>
            <p className="font-bold text-xl text-red-600">
              {monthlyStats.returned}
            </p>
          </div>
        </div>

        {/* <h1>{}</h1> */}
        <p className="my-4">عدد الطلبيات حسب الولاية</p>
        <div className="w-full bg-gray-200 p-2 h-80 overflow-auto mt-2">
          {wilayas.map((wilaya, i) => {
            return (
              <div className="flex gap-4" key={i}>
                <p>{wilaya._id}</p>
                <p className="font-bold">{wilaya.count}</p>
              </div>
            );
          })}
        </div>
      </div>
    </ControlPanel>
  );
};

export default Main;
