import "./static.css";
import NavContainer from "../../../NavContainer";

const DeliveryPolicy = () => {
  return (
    <NavContainer>
      <div className="static-container">
        <div className="static-content-container flex flex-col items-center justify-center text-center p-8 mt-16 mx-4 lg:mx-20 text-xl">
          <h1>سياسة التوصيل</h1>
          <p>
            يكفي أن تتبع الخطوات الواردة في الموقع لتصل طلبيتك إلينا، وسنتكفل
            نحن بالباقي...
          </p>
          <p>
            لدينا فريق كامل يتابع وينفذ ويتواصل معكم إلى حين استلام الكتاب...
          </p>
          <p>شركات التوصيل المتعاقدة معنا تقوم بتوصيل طلبيتك إلى باب بيتك...</p>
          <p>الدفع يكون عند استلامك للطلبية...</p>
          <p>التوصيل يتم عبر شركة للتوصيل...</p>
          <p>مدة التوصيل لن تتجاوز 10 أيام على الأكثر...</p>
          <p>التوصيل داخل العاصمة: 400دج</p>
          <p>التوصيل خارج العاصمة: 700دج</p>
        </div>
      </div>
    </NavContainer>
  );
};

export default DeliveryPolicy;
