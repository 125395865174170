import React, { useEffect, useState } from "react";
import Pagination from "../../components/organisms/Pagination";
import { axiosInstance } from "../../config";
import NavContainer from "../../NavContainer";
import Loading from "../Loading";

const MashafPage = () => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        await axiosInstance
          .get("/api/products?category=مصاحف", {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            setProducts(res.data.data);
            setLoading(false);
          });
      } catch (error) {
        console.log(error);
      }
    };
    fetchProducts();
  }, []);

  return (
    <NavContainer>
      {loading ? (
        <Loading />
      ) : (
        <div className="books-container">
          <Pagination data={products} />
        </div>
      )}
    </NavContainer>
  );
};

export default MashafPage;
