import React from "react";
import BookCard from "../molecules/BookCard";

const Suggestions = ({ title, data }) => {
  return (
    <div className="cat-container">
      <header className="cat-header ph-10 pb-40">
        <p className="text-2xl font-bold">{title}</p>
      </header>
      <div className="overflow-x-auto no-scrollbar px-2">
        <div className="flex gap-4">
          {data.map((product, index) => (
            <BookCard key={index} product={product} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Suggestions;
