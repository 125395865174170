import React, { useEffect, useState } from "react";
import ControlPanel from "../../ControlPanel";
import PageHeader from "../../components/molecules/PageHeader";
import { axiosInstance } from "../../config";
import { Link } from "react-router-dom";

const Sets = () => {
  const [sets, setSets] = useState([]);

  useEffect(() => {
    const getSets = async () => {
      const data = await axiosInstance.get("/api/sets");
      setSets(data.data.data);
    };
    getSets();
  }, []);

  return (
    <ControlPanel>
      <div className="pr-72 pl-8">
        <PageHeader title="الحزم" />
        <div className="overflow-auto">
          {sets.map((set) => {
            return (
              <div
                key={set._id}
                className="bg-blue-200 hover:bg-blue-100 p-2 rounded-xl my-1"
              >
                <Link to={`/s/${set._id}`} className="">
                  {set.name}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </ControlPanel>
  );
};

export default Sets;
