import './customers.css';
import ControlPanel from '../../ControlPanel';
import PageHeader from '../../components/molecules/PageHeader';
import CustomersTable from '../../components/molecules/CustomersTable';

const Customers = () => {
  const customers = [
    {
      id: 0,
      name: 'name',
      phone: 100,
      email: 'email@example.com',
      orders: 1,
      date: 'date',
    },
    {
      id: 0,
      name: 'name',
      phone: 100,
      email: 'email@example.com',
      orders: 1,
      date: 'date',
    },
  ];
  return (
    <ControlPanel>
      <div className="container">
        <PageHeader title="الزبائن" />
        <CustomersTable customers={customers} />
      </div>
    </ControlPanel>
  );
};

export default Customers;
