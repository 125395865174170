import "./static.css";
import NavContainer from "../../../NavContainer";

const Aboutus = () => {
  return (
    <NavContainer>
      <div className="static-container">
        <div className="static-content-container flex flex-col items-center justify-center text-center p-8 mt-16 mx-4 lg:mx-20 text-xl">
          <h1>من نحن؟</h1>
          <p>
            مجموعة من الشباب، نؤمن بمبدأ القراءة أولا. هدف نرتجيه ونوفر له ما
            تيسر لدينا من جهد، مستعملين أحدث التكنولوجيات لتوفير الكتاب لكل من
            يرغب فيه، وبنقرة واحدة...
          </p>
          <p>نقرأ بنقرة</p>
        </div>
      </div>
    </NavContainer>
  );
};

export default Aboutus;
