import "./categories.css";
import ControlPanel from "../../ControlPanel";
import PageHeader from "../../components/molecules/PageHeader";
import Input from "../../components/atoms/Input";
import { useState, useEffect, useContext } from "react";
import { axiosInstance } from "../../config";

const Categories = () => {
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data } = await axiosInstance.get("/api/categories/");
        setCategories(data.categories);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCategories();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axiosInstance.post(
      "/api/categories/",
      {
        name: category,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  const handleDelete = async (id) => {
    await axiosInstance
      .delete(`/api/categories/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        setCategories(
          categories.filter((element) => {
            return element._id !== id;
          })
        );
      });
  };

  return (
    <ControlPanel>
      <div className="container">
        <PageHeader title="الأقسام" />
        <form
          className="category-card"
          action="/cp/categories"
          onSubmit={handleSubmit}
        >
          <Input
            value={category}
            onChange={(e) => {
              setCategory(e.target.value);
            }}
            type={"text"}
            placeholder="إسم القسم"
            name="categoryName"
          />
          <input type="submit" value="إضافة" className="categories-submit" />
        </form>
        <div className="category-card">
          {categories.map((element) => (
            <div key={element._id} className="category-element">
              <p>{element.name}</p>
              <button
                className="category-btn-delete"
                onClick={() => handleDelete(element._id)}
              >
                مسح
              </button>
            </div>
          ))}
        </div>
      </div>
    </ControlPanel>
  );
};

export default Categories;
