import "./pagination.css";
import BookCard from "../molecules/BookCard";
import { useState } from "react";

const Pagination = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(20);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

  const totalPosts = data.length;

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="w-full px-2 py-10">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        {currentPosts.map((product, index) => (
          <BookCard key={index} product={product} />
        ))}
      </div>
      <div className="flex justify-center w-full gap-1">
        {pageNumbers.map((number) => (
          <button
            key={number}
            className="rounded-full bg-[#1f2f3f] text-white w-6"
            onClick={() => setCurrentPage(number)}
          >
            {number}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Pagination;
