import "./show.css";
import { Link } from "react-router-dom";
import BookCard from "../molecules/BookCard";

const Show = ({ title, data, link }) => {
  return (
    <div className="cat-container">
      <header className="cat-header ph-10 pb-40">
        <h2>{title}</h2>
        <Link to={link} className="cat-header-link">
          المزيد
        </Link>
      </header>
      <div className="overflow-x-auto no-scrollbar px-2">
        <div className="flex gap-4">
          {data.map((product, index) => (
            <BookCard key={index} product={product} />
          ))}
        </div>
      </div>
      <hr />
    </div>
  );
};

export default Show;
