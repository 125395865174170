import React from "react";
import Navbar from "./components/organisms/Navbar";
import Footer from "./components/organisms/Footer";
import { Link } from "react-router-dom";

const NavContainer = ({ children }) => {
  return (
    <>
      <Navbar />
      <div className="flex items-center justify-center py-3 px-2 bg-blue-200 text-center">
        <Link to="/mashaf" className="text-blue-700 font-bold">
          إضغط هنا للتعرف على المصاحف المتوفرة.
        </Link>
      </div>
      {children}
      <Footer />
    </>
  );
};

export default NavContainer;
