import "./bestseller.css";
import NavContainer from "../../NavContainer";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../config";
import Loading from "../Loading";
import Pagination from "../../components/organisms/Pagination";

const BestSellers = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        await axiosInstance
          .get("../api/products/", {
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              sort: "orderTimes",
            },
          })
          .then((res) => {
            setProducts(res.data.data);
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        console.log(error);
      }
    };
    fetchProducts();
  }, []);
  return (
    <NavContainer>
      {loading ? (
        <Loading />
      ) : (
        <div className="bestsellers-container">
          <Pagination data={products} />
        </div>
      )}
    </NavContainer>
  );
};

export default BestSellers;
