import React from "react";
import NavContainer from "../../../NavContainer";

const PrivacyPolicy = () => {
  return (
    <NavContainer>
      <div className="static-container">
        <div className="static-content-container flex flex-col items-center justify-center text-center p-8 mt-16 mx-4 lg:mx-20 text-xl">
          <div class="bg-white p-8 shadow-md rounded">
            <h1 class="text-2xl font-bold mb-4">Privacy Policy</h1>

            <section class="my-6">
              <h2 class="text-xl font-semibold mb-2">1. Introduction</h2>
              <p class="text-gray-700">
                Welcome to Naqra ("we", "our", "us"). We are committed to
                protecting your privacy and ensuring you have a positive
                experience on our eCommerce platform. This Privacy Policy
                outlines how we collect, use, disclose, and protect your
                information when you use our app and services.
              </p>
              <p class="text-gray-700 mt-2">
                This privacy policy is designed to provide transparency into our
                privacy practices and principles in a format that our users can
                navigate, read, and understand. If you have any questions about
                our privacy practices, please contact us at{" "}
                <a
                  href="mailto:contact@naqra.net"
                  class="text-blue-600 hover:underline"
                >
                  contact@naqra.net
                </a>
                .
              </p>
            </section>

            <section class="my-6">
              <h2 class="text-xl font-semibold mb-2">
                2. Information We Collect
              </h2>
              <p class="text-gray-700">
                When you use our app, we collect the following types of
                information:
              </p>
              <ul class="list-disc pl-5 mt-2 text-gray-700">
                <li>
                  <strong>Personal Information</strong>: This includes your
                  name, email address, phone number, and delivery address.
                </li>
                <li>
                  <strong>Order Information</strong>: Details about the products
                  you order, including quantity, price, and purchase date.
                </li>
                <li>
                  <strong>Payment Information</strong>: Payment method details,
                  but we do not store your full credit card information.
                </li>
              </ul>
            </section>

            <section class="my-6">
              <h2 class="text-xl font-semibold mb-2">
                3. How We Use Your Information
              </h2>
              <p class="text-gray-700">
                We use the information we collect for the following purposes:
              </p>
              <ul class="list-disc pl-5 mt-2 text-gray-700">
                <li>
                  <strong>Order Processing</strong>: To process and deliver your
                  orders, communicate with you about your orders, and manage
                  your orders in our system.
                </li>
                <li>
                  <strong>Customer Service</strong>: To provide customer support
                  and respond to your inquiries.
                </li>
                <li>
                  <strong>Improve Our Services</strong>: To enhance and improve
                  our app and services based on your feedback and usage
                  patterns.
                </li>
              </ul>
            </section>

            <section class="my-6">
              <h2 class="text-xl font-semibold mb-2">
                4. Information Sharing and Disclosure
              </h2>
              <p class="text-gray-700">
                We do not share your personal information with third parties
                except in the following circumstances:
              </p>
              <ul class="list-disc pl-5 mt-2 text-gray-700">
                <li>
                  <strong>Service Providers</strong>: We may share your
                  information with trusted service providers who assist us in
                  operating our app, processing payments, and delivering orders.
                </li>
                <li>
                  <strong>Legal Requirements</strong>: We may disclose your
                  information if required to do so by law or in response to
                  valid requests by public authorities (e.g., a court or a
                  government agency).
                </li>
              </ul>
            </section>

            <section class="my-6">
              <h2 class="text-xl font-semibold mb-2">5. Data Security</h2>
              <p class="text-gray-700">
                We implement appropriate technical and organizational measures
                to protect your personal information from unauthorized access,
                use, or disclosure. However, no internet-based service can be
                100% secure, so we cannot guarantee the absolute security of
                your information.
              </p>
            </section>

            <section class="my-6">
              <h2 class="text-xl font-semibold mb-2">6. Data Retention</h2>
              <p class="text-gray-700">
                We retain your personal information for as long as necessary to
                fulfill the purposes outlined in this privacy policy unless a
                longer retention period is required or permitted by law. When we
                no longer need your personal information, we will securely
                delete or anonymize it.
              </p>
            </section>

            <section class="my-6">
              <h2 class="text-xl font-semibold mb-2">7. Your Rights</h2>
              <p class="text-gray-700">
                You have the following rights regarding your personal
                information:
              </p>
              <ul class="list-disc pl-5 mt-2 text-gray-700">
                <li>
                  <strong>Access</strong>: You can request access to the
                  personal information we hold about you.
                </li>
                <li>
                  <strong>Correction</strong>: You can request that we correct
                  any inaccurate or incomplete personal information.
                </li>
                <li>
                  <strong>Deletion</strong>: You can request that we delete your
                  personal information, subject to certain legal exceptions.
                </li>
                <li>
                  <strong>Objection</strong>: You can object to the processing
                  of your personal information in certain circumstances.
                </li>
              </ul>
              <p class="text-gray-700 mt-2">
                To exercise any of these rights, please contact us at{" "}
                <a
                  href="mailto:contact@naqra.net"
                  class="text-blue-600 hover:underline"
                >
                  contact@naqra.net
                </a>
                .
              </p>
            </section>

            <section class="my-6">
              <h2 class="text-xl font-semibold mb-2">
                8. Changes to This Privacy Policy
              </h2>
              <p class="text-gray-700">
                We may update this privacy policy from time to time to reflect
                changes in our practices or legal requirements. We will notify
                you of any significant changes by posting the new privacy policy
                on our app and updating the "Last Updated" date at the top of
                this page.
              </p>
            </section>

            <section class="my-6">
              <h2 class="text-xl font-semibold mb-2">9. Contact Us</h2>
              <p class="text-gray-700">
                If you have any questions or concerns about this privacy policy
                or our data practices, please contact us at: contact@naqra.net
              </p>
            </section>
          </div>
        </div>
      </div>
    </NavContainer>
  );
};

export default PrivacyPolicy;
