import { useState, createContext } from "react";
import Swal from "sweetalert2";
import { addToCartEvent } from "./helpers/FacebookPixel";
import { useNavigate } from "react-router-dom";

const CartContext = createContext();

export function CartProvider({ children }) {
  const [items, setItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const navigate = useNavigate();

  const addToCart = (name, price, quantity, img, id) => {
    const data = items.every((item) => {
      return item.id !== id;
    });
    if (data) {
      setItems((prev) => [...prev, { name, price, quantity, img, id }]);
      Swal.fire({
        position: "top",
        icon: "success",
        title: "تمت الإضافة إلى السلة بنجاح",
        showConfirmButton: true,
        confirmButtonText: "إتمام الطلبية",
        showCancelButton: true,
        cancelButtonText: "المواصلة في التصفح",
        cancelButtonColor: "#034748",
        confirmButtonColor: "#999",
        reverseButtons: true,
      }).then((res) => {
        addToCartEvent(name, [id], price);
        if (res.isConfirmed) navigate("/cart");
      });
    } else {
      Swal.fire({
        position: "top",
        icon: "warning",
        title: "المنتج مضاف إلى السلة مسبقا",
        showConfirmButton: true,
        confirmButtonText: "إتمام الطلبية",
        showCancelButton: true,
        cancelButtonText: "المواصلة في التصفح",
        cancelButtonColor: "#034748",
        confirmButtonColor: "#495057",
        reverseButtons: true,
      }).then((res) => {
        if (res.isConfirmed) navigate("/cart");
      });
    }
    calculateTotalPrice();
  };

  const removeFromCart = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: true,
    });
    swalWithBootstrapButtons
      .fire({
        title: "متأكد من حذف المنتج من السلة؟",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "نعم",
        cancelButtonText: "لا",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          const data = items.filter((item) => {
            return item.id !== id;
          });
          setItems(data);
          calculateTotalPrice();
          swalWithBootstrapButtons.fire("تم المسح بنجاح!");
        }
      });
  };

  const emptyCart = () => {
    Swal.fire({
      position: "top",
      title: "هل أنت متأكد من أنك تريد إفراغ السلة؟",
      showConfirmButton: true,
      confirmButtonText: "متأكد",
      showCancelButton: true,
      cancelButtonText: "إغلاق",
    }).then((result) => {
      if (result.isConfirmed) {
        setItems([]);
        calculateTotalPrice();
      }
    });
  };

  const orderPlaced = () => {
    setItems([]);
    calculateTotalPrice();
  };

  const changeQuantity = (id, quantity) => {
    items.forEach((item) => {
      if (item.id === id) {
        item.quantity = parseInt(quantity);
      }
    });
    setItems(items);
    calculateTotalPrice();
  };

  const calculateTotalPrice = () => {
    const res = items.reduce((prev, item) => {
      return prev + item.price * item.quantity;
    }, 0);

    setTotalPrice(res);
  };

  // get total price
  // handle when reloading
  // increase product count
  // decrease product count
  // change count

  return (
    <CartContext.Provider
      value={{
        items,
        totalPrice,
        addToCart,
        removeFromCart,
        emptyCart,
        changeQuantity,
        calculateTotalPrice,
        orderPlaced,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export default CartContext;
