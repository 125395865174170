import "./orderPage.css";
import ControlPanel from "../../ControlPanel";
import PageHeader from "../../components/molecules/PageHeader";
import { useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { axiosInstance } from "../../config";
import UserContext from "../../UserContext";
import Modal from "../../components/molecules/Modal";
import LoadingIcon from "../../components/Icons/LoadingIcon";
import Select from "../../components/atoms/Select";
import WILAYAS from "../../wilayas";

const OrderPage = () => {
  const { id } = useParams();

  const [order, setOrder] = useState({});
  const [address, setAddress] = useState({ communes: [] });
  const [maystroData, setMaystroData] = useState({});
  const [productsDetails, setProductsDetails] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [maystroModalOpen, setMaystroModalOpen] = useState(false);

  const { token } = useContext(UserContext);

  useEffect(() => {
    const fetchOrder = async () => {
      setIsLoading(true);
      try {
        const { data } = await axiosInstance.get(`/api/orders/${id}`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setOrder(data.data.order);
        setProductsDetails(data.data.products);
        const wilaya = WILAYAS.find((item) => {
          return data.data.order.address.wilaya === item.name;
        });
        const communes = await axiosInstance.get(
          `/api/orders/communes?wilaya=${wilaya.id}`
        );
        setAddress({
          ...data.data.order.address,
          communes: communes.data.data,
          wilayaCode: wilaya.id,
        });
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    fetchOrder();
  }, [id]);

  const statusClass = (order) => {
    switch (order.status) {
      case "confirmed":
        return "text-green-700";
      case "packed":
        return "text-green-300";
      case "delilvered":
        return "text-blue-700";
      case "returned":
        return "text-yellow-600";
      case "cancelled":
        return "text-red-600";
      case "postponed":
        return "text-red-200";
      case "noAnswer":
        return "text-blue-200";
      default:
        return "text-fuchsia-700";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await axiosInstance
      .put(
        `/api/orders/${order._id}`,
        {
          status: order.status,
          note: order.note,
          deliveryMethod: order.deliveryMethod,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => {
        setIsLoading(false);
      });
    setOrder((prev) => {
      return { ...prev, status: order.status };
    });
  };

  const downloadBilling = async (id) => {
    await axiosInstance
      .get(`/api/orders/download/${id}`, {
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "order.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
        return data;
      });
  };

  const handleAddToMaystro = async (e) => {
    e.preventDefault();
    console.log({
      price: maystroData.price,
      commune: maystroData.commune,
      wilaya: address.wilayaCode,
    });
    try {
      setIsLoading(true);
      await axiosInstance.post(`api/orders/maystro/${id}`, {
        price: parseInt(maystroData.price),
        commune: parseInt(maystroData.commune),
        wilaya: address.wilayaCode,
      });

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <ControlPanel>
      <div className="mr-64">
        <PageHeader title="طلبية" />
        <div className="order-content mx-8">
          <p>الإسم: {order.clientFirstName}</p>
          <p>اللقب: {order.clientLastName}</p>
          <p>الهاتف: {order.clientPhoneNumber}</p>
          <p>الإيمايل: {order.clientEmail}</p>
          <p>تاريخ الطلبية: {new Date(order.createdAt).toDateString()}</p>
          <p>الولاية: {address.wilaya}</p>
          <p>العنوان: {address.addressDetails}</p>
          <p>الطلبية: </p>
          <table className="productstable-table">
            <thead>
              <tr>
                <th>المنتوج</th>
                <th>الكمية</th>
                <th>السعر الإجمالي</th>
              </tr>
            </thead>
            <tbody>
              {productsDetails.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.product.name}</td>
                    <td>{item.quantity}</td>
                    <td>{item.product.price * item.quantity}DA</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <p>سعر المنتجات: {order.itemsPrice}</p>
          <p>سعر التوصيل: {order.deliveryCharge}</p>
          <p>السعر الإجمالي: {order.totalPrice}</p>
          <p>وضعية الطلبية: </p>
          <p className={statusClass(order)}>{order.status}</p>

          {order.note && (
            <div className="bg-blue-100 my-2 p-2 rounded-xl">
              <p className="font-bold">ملاحظات: </p>
              <p>{order.note}</p>
            </div>
          )}
          {order.deliveryMethod && (
            <div className="my-2 p-2 rounded-xl">
              <p className="font-bold">طريقة التوصيل: </p>
              <p>{order.deliveryMethod}</p>
            </div>
          )}

          <div className="flex gap-2">
            <button
              className="bg-green-600 p-2 text-white my-2 hover:bg-green-400"
              onClick={() => setIsOpen(true)}
            >
              تعديل الوضعية
            </button>
            <button
              className={`bg-purple-700 p-2 text-white my-2 hover:bg-purple-500 ${
                loading && "opacity-40"
              }`}
              disabled={loading}
              onClick={() => setMaystroModalOpen(true)}
            >
              إضافة إلى مايسترو
            </button>
            <button
              className="table-btn-edit bg-blue-700"
              onClick={() => {
                downloadBilling(order._id);
              }}
            >
              الوصل
            </button>
            {/* <button
              className="bg-red-600 p-2 text-white my-2 hover:bg-red-400"
              onClick={() => setIsOpen(true)}
            >
              تعديل الطلبية
            </button> */}
          </div>
        </div>
      </div>

      <Modal isOpen={isOpen} setIsOpen={setIsOpen} title="تعديل الوضعية">
        <form action="/order" method="post" onSubmit={handleSubmit}>
          <Select
            title="وضعية الطلبية:"
            values={[
              { value: "pending", option: "Pending" },
              { value: "confirmed", option: "Confirmed" },
              { value: "packed", option: "Packed" },
              { value: "delilvered", option: "Delilvered" },
              { value: "cancelled", option: "Cancelled" },
              { value: "postponed", option: "Postponed" },
              { value: "noAnswer", option: "No Answer" },
              { value: "returned", option: "Returned" },
            ]}
            value={order.status || ""}
            onChange={(e) => {
              setOrder({ ...order, status: e.target.value });
            }}
          />
          <div className="">
            <label htmlFor="deliveryMethod">كيفية التوصيل: </label>
            <input
              name="deliveryMethod"
              value={order.deliveryMethod || ""}
              type="text"
              onChange={(e) => {
                setOrder({ ...order, deliveryMethod: e.target.value });
              }}
              className="rounded-xl border-[1px] w-full p-2 mt-2"
            />
          </div>
          <div className="">
            <label htmlFor="note">ملاحظات: </label>
            <textarea
              className="rounded-xl border-[1px] h-44 resize-none	w-full p-4 mt-2"
              name="note"
              id=""
              cols="30"
              rows="10"
              value={order.note || ""}
              onChange={(e) => {
                setOrder({ ...order, note: e.target.value });
              }}
            />
          </div>
          <div className="mt-4 flex gap-2">
            <button
              className={`flex gap-2 py-2 px-4 bg-[#192d42] hover:bg-[#192d60] hover:opacity-80 text-white rounded-xl drop-shadow-xl 
                ${loading ? "opacity-40" : ""}`}
              type="submit"
              disabled={loading}
            >
              {loading && <LoadingIcon />}
              تعديل
            </button>
            <button
              className={`py-2 px-4 bg-red-600 hover:bg-red-400 text-white rounded-xl drop-shadow-xl `}
              onClick={() => setIsOpen(false)}
            >
              إلغاء
            </button>
          </div>
        </form>
      </Modal>

      <Modal
        isOpen={maystroModalOpen}
        setIsOpen={setMaystroModalOpen}
        title="إضافة إلى مايسترو"
      >
        <form action="/order" method="post" onSubmit={handleAddToMaystro}>
          <Select
            title="الدائرة:"
            values={address.communes.map((item) => {
              return { value: item.id, option: item.name };
            })}
            value={maystroData.commune}
            onChange={(e) => {
              setMaystroData({ ...maystroData, commune: e.target.value });
            }}
          />

          <div className="">
            <label htmlFor="deliveryMethod">السعر الاجمالي:</label>
            <p className="text-sm">السعر الأصلي: {order.totalPrice}DA</p>
            <input
              name="deliveryMethod"
              value={maystroData.price}
              type="number"
              onChange={(e) => {
                setMaystroData({ ...maystroData, price: e.target.value });
              }}
              className="rounded-xl border-[1px] w-full p-2 mt-2"
            />
          </div>

          <div className="mt-4 flex gap-2">
            <button
              className={`flex gap-2 py-2 px-4 bg-[#192d42] hover:bg-[#192d60] hover:opacity-80 text-white rounded-xl drop-shadow-xl 
                ${loading ? "opacity-30" : ""}`}
              type="submit"
              disabled={loading}
            >
              {loading && <LoadingIcon />}
              إضافة
            </button>
            <button
              className={`py-2 px-4 bg-red-600 hover:bg-red-400 text-white rounded-xl drop-shadow-xl `}
              onClick={() => setMaystroModalOpen(false)}
            >
              إلغاء
            </button>
          </div>
        </form>
      </Modal>
    </ControlPanel>
  );
};

export default OrderPage;
