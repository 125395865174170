import React from "react";
import NavContainer from "../../../NavContainer";
import { AiFillFacebook } from "react-icons/ai";
import { FaInstagramSquare, FaWhatsappSquare } from "react-icons/fa";

const Comp = () => {
  return (
    <NavContainer>
      <div className="h-[50vh] p-4 text-center flex flex-col justify-center items-center gap-2 mt-24 sm:mt-0">
        <img src="01.png" alt="" className="w-32 mt-28 sm:mt-0" />
        <p className="my-3">مرحبا بكم في متجر نقرة للكتب.</p>
        <p className="my-3">
          شكرا لمشاركتكم في مسابقة الوعي الثقافية، مبارك للفائزين وحظا أوفر
          للبقية في قادم المسابقات بإذن الله
        </p>
        <p className="my-3">
          سيتم الإعلان عن النتائج على مواقع التواصل الإجتماعي الخاصة بنا يوم
          الأحد على الساعة 9 ليلا
        </p>
        <p className="my-3">
          للمشاركة في قادم المسابقات، يرجى متابعة صفحاتنا على مواقع التواصل
          الإجتماعي.
        </p>
        <div className="flex flex-row mt-4 gap-2 mb-32 sm:mb-0">
          <a href="https://www.facebook.com/naqraa.store" target="_blank">
            <AiFillFacebook color="#fff" size={40} />
          </a>
          <a href="https://www.instagram.com/naqraa.store" target="_blank">
            <FaInstagramSquare color="#fb3958" size={40} />
          </a>
          <a
            href="https://api.whatsapp.com/send/?phone=213561678770&text&app_absent=0"
            target="_blank"
          >
            <FaWhatsappSquare color="#25D366" size={40} />
          </a>
        </div>
      </div>
    </NavContainer>
  );
};

export default Comp;
