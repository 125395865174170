export const WILAYAS = [
  {
    id: 1,
    name: "ADRAR",
    zone: 4,
    price: 1200,
    delivery: true,
  },
  {
    id: 2,
    name: "CHLEF",
    zone: 2,
    price: 750,
    delivery: true,
  },
  {
    id: 3,
    name: "LAGHOUAT",
    zone: 4,
    price: 1000,
    delivery: true,
  },
  {
    id: 4,
    name: "OUM EL BOUAGHI",
    zone: 4,
    price: 900,
    delivery: true,
  },
  {
    id: 5,
    name: "BATNA",
    zone: 3,
    price: 800,
    delivery: true,
  },
  {
    id: 6,
    name: "BEJAIA",
    zone: 2,
    price: 750,
    delivery: true,
  },
  {
    id: 7,
    name: "BISKRA",
    zone: 4,
    price: 1000,
    delivery: true,
  },
  {
    id: 8,
    name: "BECHAR",
    zone: 4,
    price: 1000,
    delivery: true,
  },
  {
    id: 9,
    name: "BLIDA",
    zone: 2,
    price: 700,
    delivery: true,
  },
  {
    id: 10,
    name: "BOUIRA",
    zone: 2,
    price: 700,
    delivery: true,
  },
  {
    id: 11,
    name: "TAMANRASSET",
    zone: 4,
    price: 1200,
    delivery: false,
  },
  {
    id: 12,
    name: "TEBESSA",
    zone: 4,
    price: 1000,
    delivery: true,
  },
  {
    id: 13,
    name: "TLEMCEN",
    zone: 3,
    price: 850,
    delivery: true,
  },
  {
    id: 14,
    name: "TIARET",
    zone: 4,
    price: 850,
    delivery: true,
  },
  {
    id: 15,
    name: "TIZI-OUZOU",
    zone: 2,
    price: 750,
    delivery: true,
  },
  {
    id: 16,
    name: "ALGER",
    zone: 1,
    price: 400,
    delivery: true,
  },
  {
    id: 17,
    name: "DJELFA",
    zone: 4,
    price: 1000,
    delivery: true,
  },
  {
    id: 18,
    name: "JIJEL",
    zone: 4,
    price: 800,
    delivery: true,
  },
  {
    id: 19,
    name: "SETIF",
    zone: 2,
    price: 750,
    delivery: true,
  },
  {
    id: 20,
    name: "SAIDA",
    zone: 4,
    price: 850,
    delivery: true,
  },
  {
    id: 21,
    name: "SKIKDA",
    zone: 3,
    price: 750,
    delivery: true,
  },
  {
    id: 22,
    name: "SIDI BEL ABBES",
    zone: 3,
    price: 800,
    delivery: true,
  },
  {
    id: 23,
    name: "ANNABA",
    zone: 2,
    price: 850,
    delivery: true,
  },
  {
    id: 24,
    name: "GUELMA",
    zone: 4,
    price: 850,
    delivery: true,
  },
  {
    id: 25,
    name: "CONSTANTINE",
    zone: 2,
    price: 750,
    delivery: true,
  },
  {
    id: 26,
    name: "MEDEA",
    zone: 2,
    price: 750,
    delivery: true,
  },
  {
    id: 27,
    name: "MOSTAGANEM",
    zone: 3,
    price: 800,
    delivery: true,
  },
  {
    id: 28,
    name: "MSILA",
    zone: 4,
    price: 900,
    delivery: true,
  },
  {
    id: 29,
    name: "MASCARA",
    zone: 3,
    price: 800,
    delivery: true,
  },
  {
    id: 30,
    name: "OUARGLA",
    zone: 4,
    price: 1000,
    delivery: true,
  },
  {
    id: 31,
    name: "ORAN",
    zone: 2,
    price: 750,
    delivery: true,
  },
  {
    id: 32,
    name: "EL BAYADH",
    zone: 4,
    price: 1000,
    delivery: true,
  },
  {
    id: 33,
    name: "ILLIZI",
    zone: 4,
    price: 1350,
    delivery: false,
  },
  {
    id: 34,
    name: "BORDJ BOU ARRERIDJ",
    zone: 3,
    price: 750,
    delivery: true,
  },
  {
    id: 35,
    name: "BOUMERDES",
    zone: 2,
    price: 700,
    delivery: true,
  },
  {
    id: 36,
    name: "EL TAREF",
    zone: 3,
    price: 850,
    delivery: true,
  },
  {
    id: 37,
    name: "TINDOUF",
    zone: 4,
    price: 1700,
    delivery: false,
  },
  {
    id: 38,
    name: "TISSEMSILT",
    zone: 4,
    price: 800,
    delivery: true,
  },
  {
    id: 39,
    name: "EL OUED",
    zone: 4,
    price: 1000,
    delivery: true,
  },
  {
    id: 40,
    name: "KHENCHeLA",
    zone: 4,
    price: 900,
    delivery: true,
  },
  {
    id: 41,
    name: "SOUK AHRAS",
    zone: 4,
    price: 900,
    delivery: true,
  },
  {
    id: 42,
    name: "TIPAZA",
    zone: 2,
    price: 700,
    delivery: true,
  },
  {
    id: 43,
    name: "MILA",
    zone: 4,
    price: 750,
    delivery: true,
  },
  {
    id: 44,
    name: "AIN DEFLA",
    zone: 3,
    price: 800,
    delivery: true,
  },
  {
    id: 45,
    name: "NAAMA",
    zone: 4,
    price: 1000,
    delivery: true,
  },
  {
    id: 46,
    name: "AIN TEMOUCHENT",
    zone: 3,
    price: 800,
    delivery: true,
  },
  {
    id: 47,
    name: "GHARDAIA",
    zone: 4,
    price: 1000,
    delivery: true,
  },
  {
    id: 48,
    name: "RELIZANE",
    zone: 3,
    price: 800,
    delivery: true,
  },
  {
    id: 49,
    name: "El M'GHAIR",
    zone: 4,
    price: 900,
    delivery: true,
  },
  {
    id: 50,
    name: "EL MENIAA",
    zone: 4,
    price: 950,
    delivery: true,
  },
  {
    id: 51,
    name: "OULED DJELLAL",
    zone: 4,
    price: 1000,
    delivery: true,
  },
  {
    id: 52,
    name: "BORDJ BADJI MOKHTAR",
    zone: 4,
    price: 1200,
    delivery: false,
  },
  {
    id: 53,
    name: "BENI ABBES",
    zone: 4,
    price: 1200,
    delivery: true,
  },
  {
    id: 54,
    name: "TIMIMOUN",
    zone: 4,
    price: 1200,
    delivery: true,
  },
  {
    id: 55,
    name: "TOUGGOURT",
    zone: 4,
    price: 950,
    delivery: true,
  },
  {
    id: 56,
    name: "DJANET",
    zone: 4,
    price: 1500,
    delivery: false,
  },
  {
    id: 57,
    name: "IN SALAH",
    zone: 4,
    price: 1500,
    delivery: false,
  },
  {
    id: 58,
    name: "IN GUEZZAM",
    zone: 4,
    price: 1500,
    delivery: false,
  },
];

export default WILAYAS;
