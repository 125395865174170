import "./checkoutConfirmation.css";
import { useContext, useState } from "react";
import CartContext from "../../CartContext";
import { axiosInstance } from "../../config";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import LoadingIcon from "../Icons/LoadingIcon";
import { purchaseEvent } from "../../helpers/FacebookPixel";

const CheckoutConfirmation = ({
  prevStep,
  data,
  calculateTotalPrice,
  calculateItemsPrice,
}) => {
  const { items, totalPrice, orderPlaced } = useContext(CartContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();

    const finalProducts = items.map((item) => {
      return { id: item.id, quantity: item.quantity };
    });

    const submitOrder = async () => {
      setLoading(true);
      await axiosInstance
        .post("/api/orders/", {
          clientFirstName: data.firstName,
          clientLastName: data.lastName,
          clientPhoneNumber: data.phone,
          clientEmail: data.email,
          products: finalProducts,
          addressDetails: data.addressDetails,
          wilaya: data.wilaya,
          origin: "web",
          promoCode: data.promoCode,
        })
        .then(() => {
          Swal.fire({
            position: "top",
            icon: "success",
            title: "تم تأكيد الطلبية",
            text: "سنتواصل معك قريبا",
            showConfirmButton: false,
            timer: 1250,
          });
          purchaseEvent(finalProducts, calculateTotalPrice());
          setLoading(false);
        })
        .finally(() => {
          orderPlaced();
          navigate("/", { replace: true });
        });
    };
    submitOrder();
  };

  return (
    <div className="checkout-confirmation-container py-8 rounded-xl flex items-center flex-col">
      <h3>تأكيد الطلبية</h3>
      <p>الإسم: {data.firstName}</p>
      <p>اللقب: {data.lastName}</p>
      <p>الهاتف: {data.phone}</p>
      <p>الإيمايل: {data.email}</p>
      <p>الولاية: {data.wilaya}</p>
      <p>العنوان: {data.addressDetails}</p>
      <h4>الطلبية</h4>

      <table className="productstable-table">
        <thead>
          <tr>
            <th>المنتوج</th>
            <th>الكمية</th>
            <th>السعر</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => {
            return (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{item.quantity}</td>
                <td>{item.price * item.quantity}DA</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <h4>الفاتورة</h4>
      <p>
        سعر المقتنيات: {calculateItemsPrice()}DA{" "}
        {data.discountPercentage !== 0 && (
          <span className="checkout-summary-discount">
            (-{data.discountPercentage}%)
          </span>
        )}
      </p>
      <p>سعر التوصيل: {data.deliveryCharge}DA</p>
      <p>السعر الإجمالي: {calculateTotalPrice()}DA</p>

      <div className="flex mt-2">
        <button onClick={prevStep} className="checkout-confirmation-back">
          العودة
        </button>
        <button
          disabled={loading}
          onClick={handleSubmit}
          className="checkout-confirmation-submit flex gap-2"
        >
          {loading && <LoadingIcon />}
          تأكيد الطلبية
        </button>
      </div>
    </div>
  );
};

export default CheckoutConfirmation;
