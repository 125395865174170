import "./bookCard.css";
import { Link } from "react-router-dom";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import CartContext from "../../CartContext";
import { useContext } from "react";
import SaleIcon from "../Icons/SaleIcon";

const BookCard = ({ product }) => {
  const { addToCart } = useContext(CartContext);

  return (
    <div className="book-card-container">
      <div className="book-card-img-container">
        {/* <SaleIcon /> */}
        <Link to={`/p/${product._id}`}>
          <img src={product.img} alt={product.name} />
        </Link>
        <button
          className="book-card-add-to-cart-btn hover:bg-blue-800"
          onClick={() => {
            addToCart(product.name, product.price, 1, product.img, product._id);
          }}
        >
          <MdOutlineAddShoppingCart size={20} color="white" />
        </button>
      </div>
      <Link to={`/p/${product._id}`} className="book-card-title">
        {product.name}
      </Link>
      <div className="book-card-price">
        {product.sale ? (
          <span className="price-before">{product.priceBeforeSale}دج</span>
        ) : null}
        <span className="price"> &nbsp;&nbsp;&nbsp;{product.price}دج</span>
      </div>
    </div>
  );
};

export default BookCard;
