import React from "react";
import NavContainer from "../../../NavContainer";

const Contactus = () => {
  return (
    <NavContainer>
      <div className="static-container">
        <div className="static-content-container flex flex-col items-center justify-center text-center p-8 mt-16 mx-4 lg:mx-20 text-xl">
          <h1>تواصل معنا</h1>
          <p>يمكنكم التواصل معنا عبر الهاتف عبر الرقم التالي: 70 87 67 0561</p>
          <p>
            أو عبر الواتساب عبر الرابط التالي:{" "}
            <a
              href="https://api.whatsapp.com/send/?phone=213561621111&text&app_absent=0"
              target="_blank"
            >
              إضغط هنا
            </a>
          </p>
          <p>أو عبر الإيمايل: contact@naqra.net</p>
          <p>أو عبر صفحاتنا على مواقع التواصل الإجتماعي</p>
        </div>
      </div>
    </NavContainer>
  );
};

export default Contactus;
